import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { PlaystorePaymentState } from './api/calls/playstore-payment-state';

enum ItemType {
    Product = "product",
    Subscription = "subscription"
}

export type ItemDetails = {
    itemId: string,
    title: string,
    price: PaymentCurrencyAmount,
    type?: ItemType,
    description?: string,
    iconURLs?: string[],
    subscriptionPeriod?: string,
    freeTrialPeriod?: string,
    introductoryPrice?: PaymentCurrencyAmount,
    introductoryPricePeriod?: string
    introductoryPriceCycles?: number
}

type PurchaseDetails = {
    itemId: string,
    purchaseToken: string
}

interface DigitalGoodsService {
    getDetails(itemIds: string[]): Promise<ItemDetails[]>;
    listPurchases(): Promise<PurchaseDetails[]>;
    listPurchaseHistory(): Promise<PurchaseDetails[]>;
    consume(purchaseToken: string): Promise<void>;
}

export class PlayStoreNotSupportedError extends Error {}
export class PurchaseValidationError extends Error {}

@Injectable({providedIn: 'root'})
export class GooglePlayPaymentsService {
    private service: DigitalGoodsService = null;

    private static knownSKUs = [
        // Subscriptions
        'de.entrex.bildkontakte.android.premium.1m.7d.subscription',
        'de.entrex.bildkontakte.android.premium.1m.subscription',
        'de.entrex.bildkontakte.android.premium.3m.subscription',
        'de.entrex.bildkontakte.android.premium.6m.subscription',
        'de.entrex.bildkontakte.android.premium.7d.subscription',
        'de.entrex.bildkontakte.android.premium.special.1m.subscription',
        // Products
        'de.entrex.bildkontakte.android.coins.xl.unmanaged',
        'de.entrex.bildkontakte.coins.probe.unmanaged',
        'de.entrex.bildkontakte.android.coins.starter.unmanaged',
        'de.entrex.bildkontakte.android.premium.3m.unmanaged',
        'de.entrex.bildkontakte.android.coins.spezial.unmanaged',
        'de.entrex.bildkontakte.android.premium.6m.unmanaged',
        'de.entrex.bildkontakte.android.coins.xxl.unmanaged',
        'de.entrex.bildkontakte.android.premium.special.1m.unmanaged',
        'de.entrex.bildkontakte.android.coins.probe.unmanaged',
        'de.entrex.bildkontakte.android.premium.special.7d.unmanaged',
        'de.entrex.bildkontakte.android.coins.standard.unmanaged'
    ];

    protected initPromise: Promise<void>;
    protected itemDetails: ItemDetails[];

    async createInitPromise(): Promise<void> {
        throw new PlayStoreNotSupportedError();

        if (this.service) return;

        if (!('getDigitalGoodsService' in window)) throw new PlayStoreNotSupportedError();

        try {
            this.service = await window.getDigitalGoodsService('https://play.google.com/billing');
        } catch (error) {
            throw new PlayStoreNotSupportedError();
        }
        if (!this.service) throw new PlayStoreNotSupportedError();

        this.itemDetails = await this.service.getDetails(GooglePlayPaymentsService.knownSKUs);
    }

    async init(): Promise<void> {
        return this.initPromise;
    }

    async hasStore(): Promise<boolean> {
        try {
            await this.init();
            return true;
        } catch (e) {
            return false;
        }
    }

    async availableItems(): Promise<ItemDetails[]> {
        await this.init();
        return this.itemDetails;
    }

    async handleOpenPurchases(): Promise<void> {
        await this.init();

        const purchases = await this.service.listPurchases();
        purchases.forEach(purchase => {
            const validated = this.validatePurchaseOnBackend(purchase.itemId, purchase.purchaseToken);
            if (!validated) throw new PurchaseValidationError();
        });
    }

    async purchase(item: ItemDetails): Promise<boolean> {
        await this.init();

        const data = [{
                supportedMethods: 'https://play.google.com/billing',
                data: { sku: item.itemId }
            }];
        // Ignored by Google Play but required for PaymentRequest API
        const details = {
            total: {
                label: 'Total',
                amount: { currency: 'EUR', value: '0' }
            }
        };
        const request = new PaymentRequest(data, details);

        // detailsPromise param is actually not required so we can pass undefined
        const response = await request.show(undefined);
        const { purchaseToken } = response.details;

        const result = await this.validatePurchaseOnBackend(item.itemId, purchaseToken);

        if (result === PlaystorePaymentState.InProgress) {
            await response.complete('success');
            return true;
        } else if (result === PlaystorePaymentState.Finished) {
            await response.complete('success');
            return true;
        } else {
            await response.complete('fail');
            return false;
        }

    }

    private async validatePurchaseOnBackend(itemId: string, token: string): Promise<PlaystorePaymentState> {
        return await this.apiService.payment.playstoreValidatePurchase(itemId, token);
    }

    constructor(
        private apiService: ApiService
    ) {
        this.initPromise = this.createInitPromise();
        this.initPromise.then(() => this.handleOpenPurchases());
    }
}

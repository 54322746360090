import {
    Component,
    ViewChild,
} from '@angular/core';
import { ModalDialog } from '../../../service/global-modal.service';
import { Modal } from '../modal/modal';

@Component({
               selector: 'bk-modal-email-confirm',
               template: `
                             <ng-container>
                                  <modal #modal
                                         no-border
                                         [closeIcon]="false"
                                         [backgroundClose]="false">
                                      <div class="modal-content">
                                          <div class="title">{{'MODAL.EMAIL_CONFIRM.TITLE'|translate}}</div>
                                          <div class="text markdown first-last-nomargin checkbox-list" [innerHTML]="'MODAL.EMAIL_CONFIRM.TEXT'|translate:translateParams|markdown"></div>
                                      </div>
                                      <div class="button-bar">
                                          <flat-button (click)="close()">{{'MODAL.EMAIL_CONFIRM.CLOSE'|translate}}</flat-button>
                                      </div>
                                 </modal>
                             </ng-container>
                         `,
               styles: [require('./bk-modal-dialog.scss')]
           })
export class BkModalEmailConfirm implements ModalDialog {
    static readonly typeId = 'BkModalEmailConfirm';

    @ViewChild('modal', { static: true }) private modal: Modal;

    private email: string

    protected translateParams() {
        return {
            email: this.email
        };
    }

    open(config) {
        this.email = config;
        this.modal.open();
    }

    close() {
        this.modal.close();
    }

    public get typeId(): string {
        return BkModalEmailConfirm.typeId;
    }
}

import {
    Component,
    HostListener,
    Input,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    Gender,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../service/navigation.service';
import { PremiumService } from '../../../../service/premium.service';
import { SubheaderFoldable } from '../../../../shared/mat-design/subheader/subheader-foldable';
import {
    SearchService,
    SearchVisiblePage,
} from '../search.service';

@Component({
               selector:  'search-subheader',
               template: `
                             <subheader-foldable [title]="titleKey | translate ">
                                 <ng-container sub>
                                     <div class="search-header__button">
                                         <navigation-chip (click)="normalSearch()" 
                                                          [select]="searchService.visiblePage === SearchVisiblePage.Search">
                                             {{'SEARCH.NORMAL' | translate}}
                                         </navigation-chip>
                                         <navigation-chip (click)="openExtendedSearch($event)">
                                             <div>
                                                 <icon class="search-header__icon" icon="crown-outline"></icon>
                                                 {{'SEARCH.EXTENDED' | translate}}
                                             </div>
                                         </navigation-chip>
                                         <navigation-chip class="search-header__nickname-search" 
                                                          (click)="nicknameSearch()"
                                                          [select]="searchService.visiblePage === SearchVisiblePage.NicknameSearch"
                                         >
                                             <div>
                                                 <icon class="search-header__icon" icon="crown-outline"></icon>
                                                 {{'SEARCH.NICKNAME' | translate}}
                                             </div>
                                         </navigation-chip>
                                         <navigation-chip class="search-header__nickname-search" 
                                                          (click)="activeUserSearch()"
                                                          [select]="searchService.visiblePage === SearchVisiblePage.ActiveUsers"
                                         >
                                             {{'SEARCH.ACTIVE_USERS' | translate }}
                                         </navigation-chip>
                                     </div>
                                     <separator class="search-header__seperator"></separator>

                                     <search-criteria *ngIf="searchService.visiblePage === SearchVisiblePage.Search"></search-criteria>
                                     <search-nickname *ngIf="searchService.visiblePage === SearchVisiblePage.NicknameSearch"
                                                      [value]="searchService.nicknameSearch | async"
                                                      (valueChange)="searchService.nicknameSearch.next($event)"
                                                      (close)="nicknameSearch()"
                                     ></search-nickname>
                                     <div class="search-header__active-users__settings-box" *ngIf="searchService.visiblePage === SearchVisiblePage.ActiveUsers">
                                         <chip icon="wc" [closeable]="false" (click)="onOpenSettings()">{{ genderTextKey|translate }}</chip>
                                         <chip icon="event" [closeable]="false" (click)="onOpenSettings()">{{ 'ACTIVE_USERS_PREVIEW.SEARCH_AGE'|translate:ageParams }}</chip>
                                     </div>
                                 </ng-container>
                             </subheader-foldable>
                         `,
               styles: [require('./search-subheader.scss')],
           })
export class SearchSubheader {
    @Input() currentUser: CurrentUser;
    @ViewChild(SubheaderFoldable, { static: true }) private subheader: SubheaderFoldable;

    SearchVisiblePage = SearchVisiblePage;

    get titleKey(): string {
        const map = {
            [SearchVisiblePage.Search]: 'SEARCH.NORMAL',
            [SearchVisiblePage.NicknameSearch]: 'SEARCH.NICKNAME',
            [SearchVisiblePage.ActiveUsers]: 'SEARCH.ACTIVE_USERS',
        };

        return map[this.searchService.visiblePage];
    }

    constructor(public searchService: SearchService,
                private trackingService: TrackingService,
                private navigationService: NavigationService,
                private currentUserService: CurrentUserService,
                private premiumService: PremiumService
    ) {}

     openExtendedSearch($event) {
        SearchService.showExtendedSearch($event);
        this.trackingService.hit('Suche', 'TabGeklickt', 'ErweiterteSuche');
    }

    layout() {
        this.subheader.layout();
    }

    show() {
        this.subheader.open();
    }

    @HostListener('window:resize', ['$event'])
    onResize($event) {
        this.layout();
    }

    activeUserSearch() {
        this.searchService.visiblePage = SearchVisiblePage.ActiveUsers;
        this.trackingService.hit('Suche', 'TabGeklickt', 'KürzlichOnline');
    }

    normalSearch() {
        this.searchService.visiblePage = SearchVisiblePage.Search;
        this.trackingService.hit('Suche', 'TabGeklickt', 'Suche');
    }

    nicknameSearch() {
        this.trackingService.hit('Suche', 'TabGeklickt', 'SpitznameEingeben');
        let i = 0;
        this.currentUserService.currentUser
            .then(currentUser => {
                if (currentUser.isPremium()) {
                    this.subheader.open();
                    this.searchService.visiblePage = SearchVisiblePage.NicknameSearch;
                } else {
                    this.premiumService.navigateToPremium(OrderReason.NicknameSearch);
                }
            });
    }

    get genderTextKey(): string {
        if (!this.currentUser) return '-';

        switch (this.currentUser.searchGender) {
            case Gender.Male:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_MALE';
            case Gender.Female:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_FEMALE';
            case Gender.Both:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_BOTH';
            case Gender.None:
            default:
                return '-';
        }
    }

    get ageParams(): { from: string, to: string } {
        if (!this.currentUser) return { from: '0', to: '0' };

        return {
            'from': this.currentUser.ageFrom.toString(),
            'to': this.currentUser.ageTill.toString(),
        };
    }

    onOpenSettings() {
        this.navigationService.navigateTo(Routes.MyProfile, {
            fragment: ProfileEditRouteFragment.Search,
        });
    }
}

import { BkConfig } from 'BKConfig';

export const Endpoints = {
    achievement:  {
        list: () => 'api/achievements/list/',
    },
    favorite:     {
        list:           () => 'api/v2/favorites/list/',
        listDeleted:    () => 'api/v2/favorites/deleted/',
        add:            () => 'api/favorites/add/',
        delete:         () => 'api/favorites/delete/',
        changeNotice:   () => 'api/favorites/changeNotice/',
        deleteMultiple: () => 'api/favorites/deleteMultiple/',
        single:         ({ id }: KeyValue) => `api/v2/favorite/${id}/`,
    },
    lovestories:  {
        all: () => 'api/v2/latest/love-stories/',
        get: ({ id }) => `api/v2/love-story/${id}/`,
    },
    geoLocation:  {
        city:   ({ city }: KeyValue) => `api/location/city/${city}/`,
        zip:    ({ zip }: KeyValue) => `api/location/postalCode/${zip}/`,
        search: () => 'api/location/search/',
    },
    ignore:       {
        list:           () => 'api/ignore/list/',
        add:            () => 'api/ignore/add/',
        remove:         () => 'api/ignore/remove/',
        removeMultiple: () => 'api/ignore/removeMultiple/',
    },
    matching:     {
        getPartner:   () => 'api/matching/nextPartners/',
        decide:       () => 'api/matching/decide/',
        decideBatch:  () => 'api/matching/decideBatch/',
        getMatches:   () => 'api/matching/matches/',
        deleteMatch:  () => 'api/matching/delete/',
        haveMatch:    () => 'api/matching/checkHaveMatch/',
        wantToMeetMe: () => 'api/matching/wantToMeetMeReally/',
        secondChance: () => 'api/matching/secondChance/',
        wantToMeet:   () => 'api/matching/wantToMeet/',
    },
    conversation: {
        get:                  ({ convId }: KeyValue) => `api/v2/conversation/${convId}/`,
        markAsUnreadMultiple: () => 'api/v2/conversations/mark-as-unread-multiple/',
        all:                  () => 'api/messages/conversations/',
        single:               ({ convId }: KeyValue) => `api/v2/conversation/${convId}/`,
        search:               ({ phrase }: KeyValue) => `api/messages/findConversationByPartnerName/${phrase}/`,
        delete:               ({ convId }: KeyValue) => `api/messages/conversation/${convId}/delete/`,
        deleteMultiple:       () => 'api/messages/conversation/deleteMultiple/',
        report:               ({ convId }: KeyValue) => `api/messages/conversation/${convId}/report/`,
        markAsUnread:         ({ convId }: KeyValue) => `api/messages/conversation/${convId}/markAsUnread/`,
        pin:                  () => 'api/conversations/pin/',
        unpin:                () => 'api/conversations/unpin/',
        detailedNumberOfUnreadConversations: () => 'api/messages/detailed-unread-conversation-counts/',

    },
    messages:     {
        get:                       ({ convId }: KeyValue) => `api/messages/conversation/${convId}/`,
        write:                     ({ convId }: KeyValue) => `api/messages/conversation/${convId}/write/`,
        getOlder:                  ({ convId }: KeyValue) => `api/messages/conversation/${convId}/older/`,
        messages:                  ({ convId }: KeyValue) => `api/messages/conversation/${convId}/messages/`,
        delete:                    ({ convId }: KeyValue) => `api/messages/conversation/${convId}/deleteMessages/`,
        getNumberOfUnreadMessages: () => 'api/messages/numberOfUnreadMessages/',
    },
    microtrans:   {
        list:         () => 'api/microtrans/items/',
        buy:          () => 'api/microtrans/buy/',
        history:      () => 'api/microtrans/history/',
        currentCoins: () => 'api/microtrans/overview/',
        fyberReward:  () => 'api/microtrans/fyber/reward/',
    },
    news:         {
        get: () => 'api/v2/user/news/',
    },
    newsstream:   {
        get: () => 'api/newsstream/',
    },
    list:         {
        activeUsers: () => 'api/users/active/',
        profileVisitors: () => 'api/profileVisitors/',
        profilesVisited: () => 'api/profilesVisited/',
        latestUser:      () => 'api/v2/users/latest/',
        nearBy:          () => 'api/v2/users/near-by/',
        selectedUsers:   () => 'api/selectedUsers/',
    },
    search:       {
        get: () => 'api/search/',
    },
    survey: {
        get: () => 'api/surveys/',
        post: ({ type }) => `api/surveys/${type}/`
    },
    payment:      {
        session:        '',
        billingAddress:    () => 'api/payment/billing-address/',
        sepaAccount:       () => 'api/payment/sepa-account/',
        directDebit:       () => 'api/payment/direct-debit/',
        cashReference:     () => 'api/v2/payment/intended-purpose/',
        products:          () => 'api/inapp/products/',
        changeDebit:       () => 'api/payment/change-direct-debit/',
        tariff:            ({ tariffId }) => `api/premium/tariff/${tariffId}/`,
        payCash:           () => 'api/payment/cash/',
        openInvoice:       () => 'api/payment/open-invoices/',
        debitAgain:        () => 'api/payment/direct-debit/repay/',
        offers:            () => 'api/offers/',
        playstoreValidate: () => 'api/payment/play-store/',
    },
    premium:      {
        details: () => 'api/premium/details/',
        cancel:  () => 'api/payment/direct-debit/cancel-subscription/',
    },
    profile:{
        food: {
            edit: () => 'api/user/profile/food/edit/',
            all: () => 'api/user/profile/food/all/',
            fetch: () => 'api/user/profile/food/',
        },
        movies: {
            edit: () => 'api/user/profile/movies/edit/',
            all: () => 'api/user/profile/movies/all/',
            fetch: () => 'api/user/profile/movies/',
        },
        music: {
            edit: () => 'api/user/profile/music/edit/',
            all: () => 'api/user/profile/music/all/',
            fetch: () => 'api/user/profile/music/',
        }
    },
    push: {
        subscribe: () => 'api/push/web/subscribe/',
        unsubscribe: () => 'api/push/web/unsubscribe/'
    },
    validate:     {
        username:    ({ username }: KeyValue) => `api/suggestNickname/${username}/`,
        // TODO change to the other nickname validation
        // username: ({username}: KeyValue) => `api/v2/validate/username/${username}/`,
        email:       () => 'api/v2/validate/email/',
        emailExists: () => 'api/v2/email/exists/',
        user:        {
            idcard:     () => 'api/validation/idCard/upload/',
            photocheck: () => 'api/validation/photocheck/upload/',
            idWithNonce:() => 'api/validation/id-card/upload/public/',
        },

        regCode: () => 'api/v2/validate/reg-code/',
    },

    image:          {
        delete:   () => 'api/userImage/delete/',
        settings: () => 'api/userImage/changeSettings/',
        gallery:  () => 'api/userImage/gallery/',
        upload:   () => 'api/userImage/upload/',
    },
    settings:       {
        profileEdit: () => 'api/userInfo/changeProfileData/',
        password:    {
            change:         () => 'api/changePassword/',
            recoveryFinish: () => 'api/v2/password-recovery/finish/',
            recovery:       () => 'api/v2/password-recovery/',
        },
        get:         () => 'api/v2/settings/',
        push:        {
            get:    () => 'api/settings/push/',
            change: () => 'api/settings/push/change/',
        },
        email:       {
            change: () => 'api/user/email/change/',
            verify: () => 'api/user/email/verify/',
            abort: () => 'api/user/email/abort/',
            regCode: {
                resend: () => 'api/user/regCode/resend/'
            },
            intervalAll: () => 'api/settings/email/interval/all/',
            interval: () => 'api/settings/email/interval/',
            intervalMultiple: () => 'api/settings/email/interval/multiple/'
        },
        stayOnBeta:  () => 'api/user/settings/stay-on-beta/',
    },
    singleEvents:   {
        getList: () => 'api/single-events/',
    },
    authentication: {
        login:      () => 'oauth/v2/token2',
        logout:     () => 'api/logout/',
        deviceInfo: () => 'api/deviceInfo/',
        fbconnect:  () => 'api/fbconnect/',
    },
    trigger: {
        canceledPurchase: () => 'api/tracking/canceledPurchase/'
    },
    radio: {
        upcoming: () => 'api/radio/upcoming/',
        previous: () => 'api/radio/previous/',
        application: () => 'api/radio/application/',
        onAir: () => 'api/radio/on-air/',
    },

    // user data
    reportUser:        () => 'api/v2/report/user/',
    currentUser:       () => 'api/currentUser/',
    profileInfo:       () => 'api/userInfo/',
    deleteAccount:     () => 'api/delete/user/',
    userBadgeCount:    () => 'api/currentUser/badgeCount/',
    notifications:     () => 'api/currentUser/notifications/',
    photoCheckUpload:  () => 'api/validation/photocheck/upload/',
    randomUser:        () => 'api/random/members/',
    counts:            () => 'api/user/counts/',
    emailConfirmation: () => 'api/emailConfirmation/',

    profileTrackVisit: () => 'api/trackProfileVisitor/',

    feedback: () => 'api/feedback/',

    reportViolation: () => 'api/v2/report/userx/',

    terminateContracts: () => 'api/premium/cancel/',
};

export type Endpoint = (value: KeyValue) => string;

export const getImageUrl = (value: string, ratio: string, blur: boolean = false): string => {
    if (value.isEmpty()) {
        value = '0'.repeat(32);
    }
    return `${BkConfig.imageApi}/${ratio}/${value && value.slice(0, 3)}/${value && value.slice(3, 6)}/${value}${blur ? '_b' : ''}.jpg`;
};

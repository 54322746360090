import { CommonModule } from '@angular/common';
import {
    NgModule,
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
    MarkdownModule,
} from 'ngx-markdown';
import { translationConfig } from '../../app.translation';
import { AdsModule } from '../../module/intern/ads/ads.module';
import { DirectiveModule } from '../directives';

import accordion from '../mat-design/accordion';
import { PipesModule } from '../pipes';
import { RoundProgressModule } from '../svg-round-progressbar';
import { AccountMenuItem } from './account-menu/account-menu-item/account-menu-item';
import { AccountMenu } from './account-menu/account-menu/account-menu';
import { ActiveUsersPreview } from './active-users-preview/active-users-preview';
import { Article } from './article/article';
import { AudioPlayer } from './audio-player/audio-player';
import { Autocomplete } from './autocomplete/autocomplete';
import { Badge } from './badge/badge';
import { LoyaltyOfferBanner } from './banner/loyalty-offer-banner';
import { BkModalBecomeVip } from './bk-modals/bk-modal-become-vip';
import { BkModalEmailChange } from './bk-modals/bk-modal-email-change';
import { BkModalEmailConfirm } from './bk-modals/bk-modal-email-confirm';
import { BkModalEmailReminder } from './bk-modals/bk-modal-email-reminder';
import { BkModalPremiumBought } from './bk-modals/bk-modal-premium-bought';
import { BkModalPremiumPaymentError } from './bk-modals/bk-modal-premium-payment-error';
import { BkPremiumModal } from './bk-modals/bk-premium-modal';
import { Star } from './deko/star';
import { GenericModal } from './generic-modal/generic-modal';
import { GenericModalBanner } from './generic-modal/generic-modal-banner';
import { ImagesDragDrop } from './images-drag-drop/images-drag-drop';
import { ImageUploadModal } from './image-upload-modal/image-upload-modal';
import { BkLink } from './link/bk-link';
import { BirthdayOffer } from './offers/birthday-offer';
import { LoyaltyOffer } from './offers/loyalty-offer';
import { RecurringOfferBanner } from './banner/recurring-offer-banner';
import { BirthdayOfferBanner} from './banner/birthday-offer-banner';
import { SchlagerherzenOnAirBanner } from './banner/schlagerherzen/on-air/schlagerherzen-on-air-banner';
import { SchlagerherzenBanner } from './banner/schlagerherzen/schlagerherzen-banner';
import { SchlagerherzenStartpageBanner } from './banner/schlagerherzen/startpage/schlagerherzen-startpage-banner';
import { BkDialogReportViolation } from './bk-dialogs/bk-dialog-report-violation';
import { BkMissedChanceMatching } from './bk-modals/bk-missed-chance-matching';
import { BkModal } from './bk-modals/bk-modal';
import { BkModalAccountDisabled } from './bk-modals/bk-modal-account-disabled';
import { BkModalChargebackPaymentFailed } from './bk-modals/bk-modal-chargeback-payment-failed';
import { BkModalGlobalCollection } from './bk-modals/bk-modal-global-collection';
import { BkModalIdInProgress } from './bk-modals/bk-modal-id-in-progress';
import { BkModalIdRequested } from './bk-modals/bk-modal-id-requested';
import { BkModalMatch } from './bk-modals/bk-modal-match';
import { BkModalProfilePictureMessage } from './bk-modals/bk-modal-profile-picture-message';
import { BkModalRegCode } from './bk-modals/bk-modal-reg-code';
import { BannerSelector } from './banner/banner-selector';
import { OfferBanner } from './banner/offer-banner';
import { BkModalRemovedIgnoredUser } from './bk-modals/bk-modal-removed-ignored-user';
import { BkModalReturnToLegacy } from './bk-modals/bk-modal-return-to-legacy';
import { BkModalSchlagerherzenApplication } from './bk-modals/bk-modal-schlagerherzen-application';
import { BkModalSchlagerherzenError } from './bk-modals/bk-modal-schlagerherzen-error';
import { BkModalSchlagerherzenSuccess } from './bk-modals/bk-modal-schlagerherzen-success';
import { BkModalWriteMessage } from './bk-modals/bk-modal-write-message';
import { BkNps } from './bk-modals/bk-nps';
import { FlatButton } from './button/flat-button';
import { IconButton } from './button/icon-button';
import { OutlinedButton } from './button/outlined-button';
import { EpcQr } from './epc-qr/epc-qr';
import { Faq } from './faq/faq';
import { FaqItem } from './faq/faq-item';
import { PremiumLandingPageFooter } from './footer/premium-landing-page-footer';
import { ListContainer } from './list/list-container';
import { ListItemSelector } from './list/list-item-selector';
import { ImgWithLoadingIndicator } from './img-with-loading-indicator/img-with-loading-indicator';
import { ListMatchingSecondChanceItem } from './list/list-matching-second-chance-item';
import { ListMatchingUserItem } from './list/list-matching-user-item';
import { LoadingIndicator } from './loading-indicator/loading-indicator';
import { NavigationChip } from './navigation-chip/navigation-chip';
import { NotificationBox } from './notification-box/notification-box';
import { UspItem } from './offers/welcome-offer/usp-item';
import { WelcomeOffer } from './offers/welcome-offer/welcome-offer';
import { PayWithQr } from './pay-with-qr/pay-with-qr';
import {
    PaymentMethodeTab,
} from './payment-methode-tab/payment-methode-tab';
import { PaypalButton } from './button/paypal-button';
import { RaisedButton } from './button/raised-button';
import { Card } from './card/card';
import { CardOpenable } from './card/card-openable';
import { CardTitle } from './card/card-title';
import {
    CardAction,
    CardButton,
    Tile,
} from './card/tile';
import { carousel } from './carousel';
import { Checkbox } from './checkbox/checkbox';
import { Chip } from './chip/chip';
import { ChipSet } from './chip/chipSet';
import { CiBar } from './ci-bar/ci-bar';
import { CountrySelect } from './country-select/country-select';
import { Cropper } from './cropper/cropper';
import { DatePicker } from './date-picker/date-picker';
import { DialogBox } from './dialog/dialog-box';
import { FeatureExplainDialog } from './dialog/feature-explain-dialog';
import { Dropdown } from './dropdown/dropdown';
import { DropdownOption } from './dropdown/dropdown-option';
import { EmojiPicker } from './emoji-picker/emoji-picker';
import { FooterLinks } from './footer/footer-links';
import { Geolocation } from './geolocation/geolocation';
import { InternDefaultHeader } from './header/intern-default-header';
import { InternHeader } from './header/intern-header';
import { InternHeaderLeft } from './header/intern-header-left';
import { InternHeaderRight } from './header/intern-header-right';
import { InternHeaderTitle } from './header/intern-header-title';
import { HintBox } from './hint-box/hint-box';
import { Icon } from './icon/icon';
import { ImageUpload } from './image-upload/image-upload';
import { Lightbox } from './lightbox/lightbox';
import { LinearProgress } from './linear-progress/linear-progress';
import { ArrowLink } from './link/arrow-link';
import { BackArrow } from './link/back-arrow';
import list from './list';
import { Menu } from './menu/menu';
import { MenuItem } from './menu/menu-item';
import { Modal } from './modal/modal';
import { OfferModal } from './offers/offer-modal';
import { SubModal } from './modal/sub-modal';
import { OnlineState } from './online-state/online-state';
import { PageIndicator } from './page-indicator/page-indicator';
import { PasswordStrength } from './pasword-strength/password-strength';
import { PaymentDebit } from './payment-debit/payment-debit';
import { ProfileInterview } from './profile-interview/profile-interview';
import { ProfileInterviewSingle } from './profile-interview/profile-interview-single/profile-interview-single';
import { ProfileInterviewTags } from './profile-interview/profile-interview-tags/profile-interview-tags';
import { ProfileInterviewText } from './profile-interview/profile-interview-text/profile-interview-text';
import { ProfileVisitsPreview } from './profile-visits-preview/profile-visits-preview';
import { RadialProgressBar } from './radial-progress-bar/radial-progress-bar';
import { Radio } from './radio/radio';
import { RadioPanel } from './radio/radio-panel';
import { Range } from './range/range';
import { SelectFromTill } from './select-from-till/select-from-till';
import { Separator } from './seperator/separator.component';
import { SinglePageHeader } from './single-page-header/single-page-header';
import { Spinner } from './spinner/spinner';
import { Subheader } from './subheader/subheader';
import { SubheaderArrow } from './subheader/subheader-arrow';
import { SubheaderFoldable } from './subheader/subheader-foldable';
import { SubheaderMenu } from './subheader/subheader-menu';
import { PremiumSupport } from './support/premium-support';
import { Switch } from './switch/switch';
import { Tab } from './tabs/tab';
import { Tabs } from './tabs/tabs';
import {
    TextArea,
    TextareaAutosize,
} from './text-field/text-area';
import { TextField } from './text-field/text-field';
import { PremiumDayBox } from './premium-day-box/premium-day-box';


const matComponents = [
    ...accordion,
    ...carousel,
    ...list,
    AccountMenu,
    AccountMenuItem,
    ActiveUsersPreview,
    ArrowLink,
    Article,
    AudioPlayer,
    Autocomplete,
    BackArrow,
    Badge,
    BannerSelector,
    BirthdayOffer,
    BirthdayOfferBanner,
    BkDialogReportViolation,
    BkLink,
    BkModal,
    BkModalAccountDisabled,
    BkModalBecomeVip,
    BkModalChargebackPaymentFailed,
    BkModalEmailChange,
    BkModalEmailConfirm,
    BkModalEmailReminder,
    BkModalGlobalCollection,
    BkModalIdInProgress,
    BkModalIdRequested,
    BkModalMatch,
    BkModalPremiumBought,
    BkModalPremiumPaymentError,
    BkModalProfilePictureMessage,
    BkModalRegCode,
    BkModalRemovedIgnoredUser,
    BkModalReturnToLegacy,
    BkModalSchlagerherzenApplication,
    BkModalSchlagerherzenError,
    BkModalSchlagerherzenSuccess,
    BkModalWriteMessage,
    BkMissedChanceMatching,
    BkNps,
    BkPremiumModal,
    Card,
    CardAction,
    CardButton,
    CardOpenable,
    CardTitle,
    Checkbox,
    Chip,
    ChipSet,
    CiBar,
    CountrySelect,
    Cropper,
    DatePicker,
    DialogBox,
    Dropdown,
    DropdownOption,
    EmojiPicker,
    EpcQr,
    Faq,
    FaqItem,
    FeatureExplainDialog,
    FlatButton,
    FooterLinks,
    GenericModal,
    GenericModalBanner,
    Geolocation,
    HintBox,
    Icon,
    IconButton,
    ImagesDragDrop,
    ImageUpload,
    ImageUploadModal,
    ImgWithLoadingIndicator,
    InternDefaultHeader,
    InternHeader,
    InternHeaderLeft,
    InternHeaderRight,
    InternHeaderTitle,
    Lightbox,
    LinearProgress,
    ListContainer,
    ListItemSelector,
    ListMatchingSecondChanceItem,
    ListMatchingUserItem,
    LoadingIndicator,
    LoyaltyOffer,
    LoyaltyOfferBanner,
    Menu,
    MenuItem,
    Modal,
    NavigationChip,
    NotificationBox,
    OfferBanner,
    OfferModal,
    OfferModal,
    OnlineState,
    OutlinedButton,
    PageIndicator,
    PasswordStrength,
    PayWithQr,
    PaymentDebit,
    PaymentMethodeTab,
    PaypalButton,
    PremiumDayBox,
    PremiumLandingPageFooter,
    PremiumSupport,
    ProfileInterview,
    ProfileInterviewSingle,
    ProfileInterviewTags,
    ProfileInterviewText,
    ProfileVisitsPreview,
    RadialProgressBar,
    Radio,
    RadioPanel,
    RaisedButton,
    Range,
    RecurringOfferBanner,
    SchlagerherzenBanner,
    SchlagerherzenOnAirBanner,
    SchlagerherzenStartpageBanner,
    SelectFromTill,
    Separator,
    SinglePageHeader,
    Spinner,
    Star,
    SubModal,
    Subheader,
    SubheaderArrow,
    SubheaderFoldable,
    SubheaderMenu,
    Switch,
    Tab,
    Tab,
    Tabs,
    Tabs,
    TextArea,
    TextField,
    TextareaAutosize,
    Tile,
    UspItem,
    WelcomeOffer,
];


@NgModule({
              imports: [
                  CommonModule,
                  ReactiveFormsModule,
                  FormsModule,
                  PipesModule,
                  DirectiveModule,
                  TranslateModule.forChild(translationConfig), RoundProgressModule,
                  RouterModule,
                  MarkdownModule.forChild(), QRCodeModule, ImageCropperModule,
                  AdsModule,
              ],
              exports: [...matComponents],
              declarations: [...matComponents],
              entryComponents: [PayWithQr, BkLink],
              providers:    [],
          })
export class MatModule {
}

import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { Subscription } from 'rxjs';
import { BadgeCounts } from '../../../../../models/badge-counts';
import {
    Routes,
} from '../../../../../service/navigation.service';
import { StayOnBetaService } from '../../../../../service/stay-on-beta.service';

/**
 * Items der Seitennavigation
 */
@Component({
               selector:  'side-nav-navigation',
               template: `
                             <nav #nav class="side-nav-navigation">
                                 <bk-side-nav-item [route]="Routes.Startpage"
                                                   trackingLabel="Startseite"
                                 >
                                     <icon glyph icon="home-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.STARTPAGE' | translate}}
                                 </bk-side-nav-item>
                                 <bk-side-nav-item [route]="Routes.Search"
                                                   trackingLabel="Suche"
                                 >
                                     <icon glyph icon="search-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.SEARCH' | translate}}
                                 </bk-side-nav-item>
                                 <bk-side-nav-item [route]="Routes.Conversation"
                                                   [badgeCount]="badgeCounts.unreadMessages"
                                                   trackingLabel="Postfach"
                                 >
                                     <icon glyph icon="chat-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.MESSAGES' | translate}}
                                 </bk-side-nav-item>
                                 <bk-side-nav-item [route]="Routes.MatchingStart"
                                                   [badgeCount]="badgeCounts.matches+badgeCounts.openMatches"
                                                   trackingLabel="Matching"
                                 >
                                     <icon glyph icon="match-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.MATCHING' | translate}}
                                 </bk-side-nav-item>
                                 <hr class="side-nav-navigation__seperator">
                                 <bk-side-nav-item [route]="Routes.ProfileVisitors"
                                                   [badgeCount]="badgeCounts.profileVisitors"
                                                   trackingLabel="Profilbesucher"
                                 >
                                     <icon glyph icon="visitor-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.VISITOR' | translate}}
                                 </bk-side-nav-item>
                                 <bk-side-nav-item [route]="Routes.Favorite"
                                                   trackingLabel="Favoriten"
                                 >
                                     <icon glyph icon="favorite-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.FAVORITE' | translate}}
                                 </bk-side-nav-item>
                                 <hr class="side-nav-navigation__seperator">
                                 <bk-side-nav-item [route]="Routes.PremiumOverview"
                                                   trackingLabel="Premium"
                                 >
                                     <icon glyph icon="crown-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.PREMIUM' | translate}}
                                 </bk-side-nav-item>
                             </nav>
                             <ad-slot slotName="BkRl_side-nav-navigation"></ad-slot>
                         `,
               styles: [require('./side-nav-navigation.scss')],
           })
export class SideNavNavigation {
    @Input() badgeCounts: BadgeCounts = new BadgeCounts();
    @ViewChild('nav', { static: true }) private navRef: ElementRef;

    get height(): string {
        return window.getComputedStyle(this.navRef.nativeElement).height;
    }

    Routes = Routes;
}

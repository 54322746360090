import {
    Component,
    ViewChild,
} from '@angular/core';
import {
    ApiService,
    CurrentUserService,
} from 'BKService';
import {
    GlobalModalService,
    ModalDialog,
} from '../../../service/global-modal.service';
import { Modal } from '../modal/modal';
import { BkModalEmailChange } from './bk-modal-email-change';
import { BkModalEmailConfirm } from './bk-modal-email-confirm';

@Component({
               selector: 'bk-modal-email-reminder',
               template: `
                             <ng-container>
                                  <modal #modal
                                         no-border
                                         [closeIcon]="false"
                                         [backgroundClose]="false">
                                      <div class="modal-content">
                                          <div class="title">{{'MODAL.EMAIL_REMINDER.TITLE'|translate}}</div>
                                          <div class="text markdown first-last-nomargin checkbox-list" [innerHTML]="'MODAL.EMAIL_REMINDER.TEXT'|translate:{email:email}|markdown"></div>
                                      </div>
                                      <div class="button-bar">
                                          <flat-button (click)="confirm()">{{'MODAL.EMAIL_REMINDER.CONFIRM'|translate}}</flat-button>
                                          <flat-button (click)="change()">{{'MODAL.EMAIL_REMINDER.CHANGE'|translate}}</flat-button>
                                          <flat-button (click)="close()">{{'MODAL.EMAIL_REMINDER.CANCEL'|translate}}</flat-button>
                                      </div>
                                 </modal>
                             </ng-container>
                         `,
               styles: [require('./bk-modal-dialog.scss')]
           })
export class BkModalEmailReminder implements ModalDialog {
    static readonly typeId = 'BkModalEmailReminder';

    @ViewChild('modal', { static: true }) private modal: Modal;

    protected email: string

    protected busy = false;

    constructor(
        private globalModalService: GlobalModalService,
        private currentUserService: CurrentUserService
    ) {
    }

    confirm() {
        this.busy = true;
        this.currentUserService.confirmEmail()
            .then(value => {
                if (!value) throw new Error();

                this.close();
                this.globalModalService.open(BkModalEmailConfirm.typeId, this.email);
            })
            .finally(() => {
                this.busy = false;
            });
    }

    change() {
        this.close();
        this.globalModalService.open(BkModalEmailChange.typeId, this.email);
    }

    open(config) {
        this.email = config;
        this.busy = false;
        this.modal.open();
    }

    close() {
        this.modal.close();
    }

    public get typeId(): string {
        return BkModalEmailReminder.typeId;
    }
}

import { Injectable } from '@angular/core';
import { AccountState } from 'BKModels';
import { CurrentUserService } from 'BKService';
import {
    SlotCommand,
    SlotCommandType,
    Zone,
} from '../../../models/ads';

@Injectable({ providedIn: 'root' })
export class AdsService {

    private googleAdmanagerId = "40131043";
    private intervalHandle = null;
    private initialised = false;

    private googletag: any = null;

    private state: boolean = true;
    private slotConfig: { [key: string]: Zone } = {
        'BkRL_Home_unterASLL':      {
            name:            'BkRL_Home_unterASLL',
            state:           true,
            refreshTime:     0,
            isActiveForUser: () => true,
            dimension:       [],
            sizeMapping:     [
                { screenDimension: [1020, 0], zoneDimension: [[740, 250]] },
                { screenDimension: [768, 400], zoneDimension: [490, 250] },
                { screenDimension: [350, 0], zoneDimension: [344, 250] },
                { screenDimension: [0, 0], zoneDimension: [300, 250] },
            ],
        },
        'BkRL_SucheProfilkarte': {
            name:            'BkRL_SucheProfilkarte',
            state:           true,
            refreshTime:     0,
            isActiveForUser: (c) => c.accountState === AccountState.Activated,
            dimension:       [],
            sizeMapping:     [

                { screenDimension: [880, 0], zoneDimension: [340, 340]  },
                { screenDimension: [770, 0], zoneDimension: [250, 340]  },
                { screenDimension: [630, 0], zoneDimension: [320, 340] },
                { screenDimension: [481, 0], zoneDimension: [250, 340] },
                { screenDimension: [330, 0], zoneDimension: [320, 340] },
                { screenDimension: [0, 0], zoneDimension: [300, 340] },
            ],
        },
        'BkRl_Profilansicht':       {
            name:            'BkRl_Profilansicht',
            state:           true,
            refreshTime:     0,
            isActiveForUser: () => true,
            dimension:       [],
            sizeMapping:     [
                { screenDimension: [590, 0], zoneDimension: [[510,250]] },
                { screenDimension: [330, 0], zoneDimension: [[280, 200]] },
                { screenDimension: [0, 0], zoneDimension: [[240, 150]] },
            ],
        },
        'BkRl_side-nav-navigation': {
            name:            'BkRl_side-nav-navigation',
            state:           true,
            refreshTime:     90,
            isActiveForUser: () => true,
            dimension:       [],
            sizeMapping:     [
                { screenDimension: [770, 1300], zoneDimension: [[220, 590]] },
                { screenDimension: [770, 1080], zoneDimension: [[220, 350]] },
                { screenDimension: [770, 864], zoneDimension: [[220, 150]] },
                { screenDimension: [770, 767], zoneDimension: [[220, 60]] },
                { screenDimension: [0, 896], zoneDimension: [[220, 60]] },
                { screenDimension: [0, 873], zoneDimension: [[220, 40]] },
                { screenDimension: [0, 1300], zoneDimension: [[220, 500]] },
            ],
        },
        'BkRl_right-nav': {
            name:            'BkRl_right-nav',
            state:           true,
            refreshTime:     60,
            isActiveForUser: () => true,
            dimension:       [],
            sizeMapping:     [
                { screenDimension: [1300, 1000], zoneDimension: [260, 200] },
                { screenDimension: [1300, 620], zoneDimension: [260, 120] },
                { screenDimension: [0, 0], zoneDimension: [260, 100] },
            ],
        },
    };
    private slots: any = [];

    private workQueues: { [key: string]: SlotCommand[] } = { define: [], display: [], destroy: [] };

    constructor(private currentUserService: CurrentUserService) {
        this.intervalHandle = setInterval(() => this.mainloop(), 2000);
    }

    generateId() {
        return 'div-' + Math.floor(Math.random() * 10000000).toString();
    }

    async isSlotActive(slotName: string): Promise<boolean> {
        if (!this.state) return false;
        if (!(slotName in this.slotConfig)) return false;
        const config = this.slotConfig[slotName];
        if (!config.state) return false;

        const currentUser = await this.currentUserService.currentUser;
        return config.isActiveForUser(currentUser) && (!currentUser.isPremium() || currentUser.isVip());
    }

    defineSlot(slotName: string, slotId: string): void {
        this.workQueues.define.push({ slotId, slotName, type: SlotCommandType.Define });
    }

    displaySlot(slotName: string, slotId: string): void {
        this.workQueues.display.push({ slotId, slotName, type: SlotCommandType.Display });
    }

    destroySlot(slotName: string, slotId: string): void {
        this.workQueues.destroy.push({ slotId, slotName, type: SlotCommandType.Destroy });
    }

    mainloop() {
        if (!this.initialised) return this.initialise();
        this.doDefineSlots();
        this.doRefreshSlots();
        this.doDestroySlots();
    }

    private initialise() {
        let googletag = window['googletag'] || {};
        if (window['googletag'] && googletag.apiReady) {
            googletag.cmd = googletag.cmd || [];
            googletag.pubads().collapseEmptyDivs();
            googletag.pubads().setCentering(true);
            googletag.pubads().disableInitialLoad();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().set('page_url', 'https://www.bildkontakte.de');
            this.initialised = true;
            this.googletag = googletag;
        }
    }

    private doDefineSlots() {
        while (this.workQueues.define.length > 0) {
            let command = this.workQueues.define.shift();

            let slotName = command.slotName;
            let slotId = command.slotId;
            let config = this.slotConfig[slotName];
            this.googletag.cmd.push(() => {
                if (this.slots[slotId] !== undefined) {
                    this.googletag.destroySlots([this.slots[slotId]]);
                }

                let sizeMapping = this.googletag.sizeMapping();
                for (let zoneSize of config.sizeMapping) {
                    sizeMapping.addSize(zoneSize.screenDimension, zoneSize.zoneDimension);
                }

                let myElement = document.getElementById(slotId);
                this.slots[slotId] = this.googletag.defineSlot('/'+this.googleAdmanagerId+'/'+this.slotConfig[slotName].name, this.slotConfig[slotName].dimension, slotId);
                this.slots[slotId].addService(this.googletag.pubads());
                this.slots[slotId].defineSizeMapping(sizeMapping.build());
                if (this.slotConfig[slotName].refreshTime > 0) {
                    let rt = this.slotConfig[slotName].refreshTime * 1000;
                    setTimeout(() => this.refreshSlot(slotId, slotName, rt), rt);
                }
            });
        }
        this.googletag.enableServices();
    }

    // TODO rewrite for angular
    private refreshSlot(slotId: string, slotName: string, rt: number){
        this.displaySlot(slotName, slotId);

        const myElement = document.getElementById(slotId);
        if (myElement && myElement.getBoundingClientRect().width > 0) {
            setTimeout(() => this.refreshSlot(slotId, slotName, rt), rt);
        }
    }

    private doRefreshSlots() {
        if (this.workQueues.display.length === 0) return;

        let refreshSlots: any = [];
        while (this.workQueues.display.length > 0) {
            let command = this.workQueues.display.shift();
            refreshSlots.push(this.slots[command.slotId]);
        }
        this.googletag.pubads().refresh(refreshSlots);
    }

    private doDestroySlots() {
        while (this.workQueues.destroy.length > 0) {
            let command = this.workQueues.destroy.shift();
            this.googletag.destroySlots([this.slots[command.slotId]]);
            this.slots[command.slotId] = undefined;
        }
    }
}

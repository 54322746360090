import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import { OrderReason } from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { PremiumService } from '../../../../service/premium.service';
import {
    SearchService,
    SearchVisiblePage,
} from '../search.service';

@Component({
               selector: 'search-header-menu',
               template: `
                             <menu>
                                 <menu-item *ngIf="searchService.visiblePage !== SearchVisiblePage.Search"
                                 iconName="search-outline"
                                 (click)="normalSearch()"
                                 >
                                     {{'SEARCH.NORMAL' | translate }}
                                 </menu-item>
                                 <menu-item iconName="crown-outline" (click)="openExtendedSearch()">
                                     {{'SEARCH.EXTENDED' | translate}}
                                 </menu-item>
                                 <menu-item *ngIf="searchService.visiblePage !== SearchVisiblePage.NicknameSearch"
                                            iconName="crown-outline"
                                            (click)="nicknameSearch()"
                                 >
                                     {{'SEARCH.NICKNAME' | translate }}
                                 </menu-item>              
                                 <menu-item *ngIf="searchService.visiblePage !== SearchVisiblePage.ActiveUsers"
                                            iconName="search-outline"
                                            (click)="activeUsers()"
                                 >
                                     {{'SEARCH.ACTIVE_USERS' | translate }}
                                 </menu-item>
                             </menu>
                         `,
           })
export class SearchHeaderMenu {
    @Output() changeMode = new EventEmitter();

    SearchVisiblePage = SearchVisiblePage;

    constructor(
        public searchService: SearchService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
        private premiumService: PremiumService
    ) {
    }

    activeUsers() {
        this.searchService.visiblePage = SearchVisiblePage.ActiveUsers;
        this.changeMode.emit();
        this.trackingService.hit('Suche', 'TabGeklickt', 'KürzlichOnline');
    }

    normalSearch() {
        this.searchService.visiblePage = SearchVisiblePage.Search;
        this.changeMode.emit();
        this.trackingService.hit('Suche', 'TabGeklickt', 'Suche');
    }

    nicknameSearch() {
        this.trackingService.hit('Suche', 'TabGeklickt', 'SpitznameEingeben');
        let i = 0;
        this.currentUserService.currentUser
            .then(currentUser => {
                if (currentUser.isPremium()) {
                    this.searchService.visiblePage = SearchVisiblePage.NicknameSearch;
                    this.changeMode.emit();
                } else {
                    this.premiumService.navigateToPremium(OrderReason.NicknameSearch);
                }
            });
    }

    openExtendedSearch() {
        this.trackingService.hit('Suche', 'TabGeklickt', 'ErweiterteSuche');
        if(this.currentUserService.cachedCurrentUser.isPremium()) {
            SearchService.showExtendedSearch();
        }else{
            this.premiumService.navigateToPremium(OrderReason.ExtendedSearch);
        }
    }
}

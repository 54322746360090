import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { BadgeCounts } from 'BKModels';
import { CurrentUserService } from 'BKService';
import { Subscription } from 'rxjs';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector: 'matching-tabbar',
               template: `
                             <div class="matching-chip-bar scroll-horizontal-hint-bp2-max">
                                 <navigation-chip (click)="navigationService.navigateTo(Routes.MatchingStart)" [select]="navigationService.isRouteActive(Routes.MatchingStart)">
                                     {{ 'MATCHING.TABBAR.MATCHING' | translate }}
                                 </navigation-chip>
                                 <navigation-chip (click)="navigationService.navigateTo(Routes.MatchingYouWantMeet)" [select]="navigationService.isRouteActive(Routes.MatchingYouWantMeet)">
                                     {{ 'MATCHING.TABBAR.YOU_WANT' | translate }}
                                 </navigation-chip>
<!--                                 <navigation-chip (click)="navigationService.navigateTo(Routes.MatchingSecondChance)" [select]="navigationService.isRouteActive(Routes.MatchingSecondChance)">
                                     {{ 'MATCHING.TABBAR.SECOND_CHANCE' | translate }}
                                 </navigation-chip>-->
                                 <navigation-chip (click)="navigationService.navigateTo(Routes.MatchingPartner)" [select]="navigationService.isRouteActive(Routes.MatchingPartner)">
                                     <span class="chip-content">
                                         {{ matchesTranslationKey | translate:{ 'totalMatches': badgeCounts.totalMatches.toString() } }}
                                         <badge [count]="badgeCounts.matches"></badge>
                                     </span>
                                 </navigation-chip>
                                 <navigation-chip (click)="navigationService.navigateTo(Routes.MatchingLikeYou)" [select]="navigationService.isRouteActive(Routes.MatchingLikeYou)">
                                     <span class="chip-content">
                                         {{ 'MATCHING.TABBAR.WANT_YOU' | translate }}
                                         <badge [count]="badgeCounts.openMatches"></badge>
                                     </span>
                                 </navigation-chip>
                             </div>
                         `,
               styles: [require('./matching-tabbar.scss')]
           })

export class MatchingTabbar implements OnInit, OnDestroy {
    Routes = Routes;

    private badgeCountSubscription = new Subscription();

    badgeCounts = new BadgeCounts();

    matchesTranslationKey = 'MATCHING.TABBAR.MATCHING_PARTNER.EMPTY';

    public constructor(
        public navigationService: NavigationService,
        public currentUserService: CurrentUserService
    ) {
    }

    ngOnDestroy(): void {
        this.badgeCountSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.badgeCountSubscription = this.currentUserService.badgeCountsObservable
            .subscribe(value => {
                this.badgeCounts = value;
                this.matchesTranslationKey = this.badgeCounts.totalMatches > 0 ? 'MATCHING.TABBAR.MATCHING_PARTNER' : 'MATCHING.TABBAR.MATCHING_PARTNER.EMPTY';
            });

        this.currentUserService.refreshBadgeCount();
    }
}


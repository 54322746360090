import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    CurrentUser,
    ProfileInfo,
} from 'BKModels';
import {
    ApiService,
    CurrentUserService,
    TrackingService,
} from 'BKService';
import {
    addLoginEventListener,
    addLogoutEventListener,
} from 'BKUtils';
import * as moment from 'moment';
import {
    BehaviorSubject,
    Subscription,
} from 'rxjs';
import {
    ApiNotification,
    ApiNotificationType,
} from '../../models/api-notification';
import {
    NavigationService,
    Routes,
} from '../navigation.service';
import {
    FailedChargebackPaymentInfo,
    PremiumService,
} from '../premium.service';
import { SessionService } from '../session.service';
import { AccountActivationNotification } from './AccountActivationNotification';
import { MessageSystemMaintenanceNotification } from './MessageSystemMaintenanceNotification';
import { MissingDoubleOptInNotification } from './MissingDoubleOptInNotification';
import {
    Notification,
} from './Notification';
import { OpenInvoiceNotification } from './OpenInvoiceNotification';
import { ChargebackPaymentFailedNotification } from './ChargebackPaymentFailedNotification';
import { PlayStorePaymentHoldNotification } from './PlayStorePaymentHoldNotification';
import { RemainingPremiumDaysNotification } from './RemainingPremiumDaysNotification';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private readonly PremiumExpirationDays = 7;

    private currentNotifications: Notification[] = [];

    private notificationSource = new BehaviorSubject<Notification[]>([]);
    notifications$ = this.notificationSource.asObservable();

    private currentUserSubscription: Subscription = null;

    private currentUserProfileSubscription: Subscription = null;

    private apiNotificationsSubscription: Subscription = null;

    private failedChargebackPaymentInfoSubscription: Subscription = null;

    public constructor(
        private currentUserService: CurrentUserService,
        private navigationService: NavigationService,
        private sessionService: SessionService,
        private premiumService: PremiumService,
        private datePipe: DatePipe,
        private trackingService: TrackingService,
    ) {
        addLoginEventListener(this.onLogin);
        addLogoutEventListener(this.onLogout);

        if (sessionService.isLoggedIn()) this.onLogin();
    }

    public get latest(): Notification[] {
        return this.notificationSource.getValue();
    }

    private onLogin() {
        this.currentUserSubscription = this.currentUserService
            .currentUserProfileInfo
            .subscribe(value => this.handleCurrentUserProfileUpdate(value));
        this.currentUserSubscription = this.currentUserService
            .currentUserObservable
            .subscribe(value => this.handleCurrentUserUpdate(value));
        this.apiNotificationsSubscription = this.currentUserService
            .notifications$
            .subscribe(value => this.handleNotificationsUpdate(value));
        this.failedChargebackPaymentInfoSubscription = this.premiumService
            .failedChargebackPaymentInfo$
            .subscribe(value => this.handleFailedChargebackPaymentInfo(value));
    }

    private onLogout() {
        if (this.currentUserSubscription) this.currentUserSubscription.unsubscribe();
        if (this.currentUserProfileSubscription) this.currentUserProfileSubscription.unsubscribe();
        if (this.apiNotificationsSubscription) this.apiNotificationsSubscription.unsubscribe();
        if (this.failedChargebackPaymentInfoSubscription) this.failedChargebackPaymentInfoSubscription.unsubscribe();

        this.currentNotifications = [];
    }

    private handleCurrentUserProfileUpdate(profileInfo: ProfileInfo) {
        if (!profileInfo.dislike.isEmpty() || !profileInfo.aboutme.isEmpty()) {
            // BKRL-2823 disabled notification
            //this.addNotification(new ProfileInfoDeprecationNotification(this.navigationService, this.datePipe));
        }
    }

    private handleCurrentUserUpdate(currentUser: CurrentUser) {
        let remainingPremiumDuration = moment.duration(moment(currentUser.premiumTill).diff(moment.now())).asDays();
        if (currentUser.isPremium() && !currentUser.hasSubscription && remainingPremiumDuration < this.PremiumExpirationDays) {
            this.addNotification(new RemainingPremiumDaysNotification(this.premiumService));
        } else {
            this.removeNotificationWithId(RemainingPremiumDaysNotification.notificationTypeId);
        }

        if (currentUser.hasOpenInvoice() && !currentUser.invoice) {
            this.addNotification(new OpenInvoiceNotification(currentUser.nickname, this.navigationService.getSerializedRoute(Routes.PremiumOverview)));
        } else {
            this.removeNotificationWithId(OpenInvoiceNotification.notificationTypeId);
        }

        if (currentUser.emailValidated) {
            this.removeNotificationWithId(MissingDoubleOptInNotification.notificationTypeId);
        } else {
            this.addNotification(new MissingDoubleOptInNotification(this.navigationService));
        }
    }

    private handleFailedChargebackPaymentInfo(failedChargebackPaymentInfo: FailedChargebackPaymentInfo) {
        if (failedChargebackPaymentInfo) {
            this.addNotification(new ChargebackPaymentFailedNotification(
                failedChargebackPaymentInfo.value,
                failedChargebackPaymentInfo.paymentDue,
                failedChargebackPaymentInfo.accountOwner,
                failedChargebackPaymentInfo.iban,
                failedChargebackPaymentInfo.bic,
                failedChargebackPaymentInfo.reference
            ));
        }else{
            this.removeNotificationWithId(ChargebackPaymentFailedNotification.notificationTypeId);
        }
    }

    public addNotification(notification: Notification) {
        if (this.currentNotifications.contains(value => value.notificationId === notification.notificationId)) return;

        this.currentNotifications.push(notification);
        this.currentNotifications.sort((a, b) => a.importance < b.importance ? -1 : a.importance > b.importance ? 1 : 0);
        this.updateNotifications();
    }

    public removeNotification(notification: Notification) {
        this.currentNotifications = this.currentNotifications.filter(value => value.notificationId !== notification.notificationId);
        this.updateNotifications();
    }

    public removeNotificationWithId(notificationId: string) {
        this.currentNotifications = this.currentNotifications.filter(value => value.notificationId !== notificationId);
        this.updateNotifications();
    }

    private updateNotifications() {
        this.notificationSource.next(this.currentNotifications);
    }

    private handleNotificationsUpdate(notifications: ApiNotification[]) {
        for (let notification of notifications) {
            if (notification.type === ApiNotificationType.ProfileActivated) {
                this.addNotification(new AccountActivationNotification());
                this.trackingService.hit('ProfilFreischaltung', 'FrontendInformiert', 'ErfolgreichFreigeschaltet');
            }
            if (notification.type === ApiNotificationType.PlayStorePaymentHold) {
                this.addNotification(new PlayStorePaymentHoldNotification());
            }
            if (notification.type === ApiNotificationType.MessageSystemMaintenance) {
                this.addNotification(new MessageSystemMaintenanceNotification());
            }
        }
    }
}

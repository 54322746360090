import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BkConfig } from 'BKConfig';
import {
    CurrentUser,
    ErrorNumber,
    Message,
    MessageInterface,
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import {
    CommunicationService,
    CurrentUserService,
    ModalService,
    TrackingService,
} from 'BKService';
import {
    startLoadingBar,
    stopLoadingBar,
} from 'BKUtils';
import * as moment from 'moment';
import {
    interval,
    Subject,
} from 'rxjs';
import {
    takeUntil,
    timeInterval,
} from 'rxjs/operators';
import { ConversationService } from '../../../../../service/conversation.service';
import { IgnoreService } from '../../../../../service/ignore.service';
import { MessageService } from '../../../../../service/message.service';
import {
    NavigationService,
    RouteParameter,
} from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';
import { ProfileService } from '../../../../../service/profile.service';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';
import {
    Cache,
    CacheKey,
} from '../../../../../utils/cache';
import { SpamReport } from '../../spam-report/spam-report';
import { ChatErrorDialogs } from '../chat-error-dialog';

@Component({
               selector: 'chat',
               template: `
                             <div class="chat">
                                 <chat-header id="chatHeader"
                                              [curPartner]="curPartner"
                                              (ignoreAdd)="ignore()"
                                              (ignoreRemove)="ignoreRemove()"
                                              [conversationId]="conversationId"
                                              [(edit)]="edit"
                                              [selectedMessages]="selectedMessages"
                                              (deleteMessages)="openDeleteDialog()"
                                              (openSpamReport)="openSpamReport()"
                                 ></chat-header>
                                 <div *ngIf="!isSystemMsg"
                                      class="chat__wrapper"
                                      id="chatWrapper"
                                 >
                                     <div class="chat__list" #chatEntryContainer>
                                         <loading-spinner *ngIf="isLoading" class="chat-list__loading"></loading-spinner>

                                         <a class="chat__list__reload"
                                            (click)="getOlder()"
                                            *ngIf="moreMessages"
                                         >
                                             <div class="chat__list__reload__text">
                                                 {{'CHAT.LIST.LOADER' | translate }}
                                             </div>
                                         </a>

                                         <div *ngFor="let entry of chatList.entries; let last = last"
                                              class="chat-list__msg-box"
                                              (click)="dialogBox.open(entry)"
                                         >
                                             <ng-container [ngSwitch]="entry.type">
                                                 <chat-msg-box *ngSwitchCase="entryTypes.Message"
                                                               [msg]="asMessageEntry(entry).msg"
                                                               [partner]="curPartner"
                                                               [curUser]="curUser"
                                                               [edit]="edit"
                                                               [last]="last"
                                                               [error]="false"
                                                               [convRead]="conversationRead"
                                                               [selected]="asMessageEntry(entry).selected"
                                                               (selectedChange)="onSelectChanged(entry, $event)"
                                                 ></chat-msg-box>
                                                 <chat-msg-box *ngSwitchCase="entryTypes.SentMessageSuccessfully"
                                                               [msg]="asMessageEntry(entry).msg"
                                                               [partner]="curPartner"
                                                               [curUser]="curUser"
                                                               [edit]="edit"
                                                               [last]="last"
                                                               [error]="false"
                                                               [convRead]="conversationRead"
                                                               [selected]="asMessageEntry(entry).selected"
                                                               (selectedChange)="onSelectChanged(entry, $event)"
                                                 ></chat-msg-box>
                                                 <chat-msg-box *ngSwitchCase="entryTypes.SentMessage"
                                                               [msg]="asSentMessageEntry(entry).msg"
                                                               [partner]="curPartner"
                                                               [curUser]="curUser"
                                                               [edit]="edit"
                                                               [last]="last"
                                                               [error]="asSentMessageEntry(entry).error"
                                                               [convRead]="conversationRead"
                                                               [selected]="asSentMessageEntry(entry).selected"
                                                               (selectedChange)="onSelectChanged(entry, $event)"
                                                 ></chat-msg-box>
                                                 <chat-date *ngSwitchCase="entryTypes.DateHeader"
                                                            [sendtime]="asDateHeaderEntry(entry).date.unix()"
                                                 ></chat-date>
                                                 <chat-unread-separator *ngSwitchCase="entryTypes.NewMessageHeader">
                                                 </chat-unread-separator>
                                             </ng-container>
                                         </div>
                                     </div>

                                     <div class="chat__controls">
                                         <chat-report
                                                 *ngIf="showReportBar && !curPartner.ignore"
                                                 (ignoreAdd)="ignore()"
                                                 (ignoreRemove)="ignoreRemove()"
                                                 (report)="openSpamReport()"
                                                 [partner]="curPartner">
                                         </chat-report>

                                         <chat-input (send)="sendMessage($event)"
                                                     [currentUser]="curUser"
                                                     [partner]="curPartner"
                                                     [conversationId]="conversationId"
                                                     (removeIgnore)="ignoreRemove()"
                                                     [disabled]="edit"
                                                     (keyup)="showReportBar = false"
                                         ></chat-input>
                                     </div>
                                 </div>

                                 <system-msg *ngIf="isSystemMsg"
                                             [systemMessage]="systemMsg"
                                             [isLoading]="isLoading"
                                 ></system-msg>
                             </div>

                             <dialog-box #deleteDialog
                                         [acceptText]="'CHAT.DELETE-DIALOG.ACCEPT_TEXT' | translate"
                                         (onAccept)="deleteMessages()"
                                         [rejectText]="'CHAT.DELETE-DIALOG.REJECT_TEXT' | translate"
                                         (onReject)="edit = false"
                                         [title]="'CHAT.DELETE-DIALOG.TITLE' | translate"
                             >
                                 <span class="delete-dialog__text" [innerHTML]="'CHAT.DELETE-DIALOG.CONTENT' | translate:conversationPartnerTranslateParam | markdown"></span>
                                 <a (click)="openSpamReport()">{{'CHAT.DELETE-DIALOG.REPORT_SPAM' | translate}}</a>
                             </dialog-box>

                             <spam-report #spamReport [conversationId]="conversationId"></spam-report>

                             <chat-error-dialogs #dialogboxen
                                                 [curUser]="curUser"
                                                 [curPartner]="curPartner"
                                                 (removeIgnore)="ignoreRemove()"
                                                 (resend)="resendErrorMsg()"
                             ></chat-error-dialogs>

                         `,

               styles: [require('./chat.scss')],
           })
export class Chat implements OnInit, OnDestroy {
    protected curPartner = new ProfileInfo();
    protected curUser = this.currentUserService.cachedCurrentUser;
    protected unsubscribe$ = new Subject();
    protected conversationId: string = '';
    protected isLoading: boolean = true;
    protected conversationRead = false;
    protected loadOlderCounter: number = 0;
    protected isPolling: boolean = false;
    protected unreadCounter = 0;
    protected moreMessages = false;
    protected showReportBar = true;
    @ViewChild('deleteDialog', { static: true }) private deleteDialog: DialogBox;
    @ViewChild('spamReport', { static: true }) private spamReport: SpamReport;
    @ViewChild('dialogboxen', { static: true }) protected dialogBox: ChatErrorDialogs;
    @ViewChild('chatEntryContainer', { static: false }) private chatEntryContainer: ElementRef<HTMLDivElement>;

    protected get conversationOwnerId(): number {
        const [ownerId, partnerId, refNumber] = this.splitConversationId;
        return ownerId.toInt();
    }

    protected get conversationPartnerId(): number {
        const [ownerId, partnerId, refNumber] = this.splitConversationId;
        return partnerId.toInt();
    }

    protected get conversationRefNumber(): number {
        const [ownerId, partnerId, refNumber] = this.splitConversationId;
        return refNumber.toInt();
    }

    protected get splitConversationId(): string[] {
        return this.conversationId.split('-');
    }

    protected chatList = new ChatList(this.curUser);

    private _edit = false;

    protected get edit(): boolean {
        return this._edit;
    }

    protected set edit(val: boolean) {
        this._edit = val;
    }

    protected get conversationPartnerTranslateParam() {
        return {
            conversationPartner: this.curPartner.nickname,
        };
    }

    private get scrolledToBottom(): boolean {
        if (!this.chatEntryContainer) return false;

        const container = this.chatEntryContainer.nativeElement;
        return container.scrollHeight === (container.scrollTop + container.clientHeight);
    }

    public ngOnInit(): void {
        this.conversationId = this.activeRoute.snapshot.params[RouteParameter.ConversationId];
        this.loadCurrentPartner();
        this.loadMessages();
        Cache.getInstance().delete(CacheKey.UnreadConversationWithoutSystem, CacheKey.AllConversation);

        this.currentUserService.currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(val => this.curUser = val);


        if (!this.isSystemMsg) {
            interval(BkConfig.chatRefreshInterval)
                .pipe(timeInterval(), takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.poll();
                });
        }
    }

    public constructor(
        private activeRoute: ActivatedRoute,
        private ignoreService: IgnoreService,
        private currentUserService: CurrentUserService,
        private modalService: ModalService,
        private conversationService: ConversationService,
        private messageService: MessageService,
        private communicationService: CommunicationService,
        private profileService: ProfileService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private trackingService: TrackingService,
    ) {
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        stopLoadingBar();
    }

    protected ignore() {
        this.trackingService.hit('Konversation', 'Auswahl', 'Ignorieren');
        this.ignoreService.add(this.curPartner)
            .then(() => this.communicationService.growl.ignore.add.handleSuccess(this.curPartner))
            .catch(() => this.communicationService.growl.ignore.add.handleError(this.curPartner));
        this.curPartner.ignore = true;
    }

    protected ignoreRemove() {
        this.ignoreService.remove(this.curPartner)
            .then(() => this.communicationService.growl.ignore.remove.handleSuccess(this.curPartner))
            .catch(() => this.communicationService.growl.ignore.remove.handleError(this.curPartner));
        this.curPartner.ignore = false;
        this.resendErrorMsg();
    }


    protected resendErrorMsg() {
        const errorMsg = this.chatList.getMessagesWithError();
        this.chatList.removeMessagesWithError();

        errorMsg.forEach(it => {
            this.sendMessage(it.msg.text);
        });
    }

    private loadCurrentPartner() {
        this.profileService.getUserProfile(this.conversationPartnerId).then(profile => this.curPartner = profile);
    }

    protected sendMessage(text: string) {
        const newMsg = Message.createDirty(Math.floor(Math.random() * 2000000000), this.conversationId, text);
        newMsg.lastSenderMsg = true;

        let sentMessage = this.chatList.addSentMessage(newMsg);
        sentMessage.error = true;
        this.conversationRead = false;

        startLoadingBar();
        setTimeout(() => this.scroll());

        this.messageService.send(this.conversationId, text)
            .then(value => {
                this.messageService.clearUnsentMessage(this.conversationId);
                sentMessage.msg.id = value.data.lastMessageId;
                sentMessage.msg.sendtime = value.data.lastMessageSendtime;
                sentMessage.msg.sendtimeMilliseconds = value.data.lastMessageSendtimeMs;
                this.chatList.transformSentMessage(sentMessage);

                const firstMessage = this.chatList.firstMessage;
                if (firstMessage && firstMessage.id === sentMessage.msg.id) {
                    this.trackingService.hit('Aktivitaet', 'KonversationBegonnen', 'NachrichtVersendet');
                } else {
                    const messageBefore = this.chatList.before(sentMessage.msg);
                    if (messageBefore !== null && messageBefore.senderId !== sentMessage.msg.senderId) {
                        this.trackingService.hit('Aktivitaet', 'KonversationFortgesetzt', 'NachrichtGeantwortet');
                    }
                }
            })
            .catch(err => {
                sentMessage.error = true;
                sentMessage.errorNumber = err.errno;
                //this.growlTemplateService.message.send.handleError(err.errno, this.curPartner);
            })
            .finally(() => {
                stopLoadingBar();
            });
    }

    private scroll() {
        const container = this.chatEntryContainer.nativeElement
        container.scroll({
                             top:      container.scrollHeight,
                             left:     0,
                             behavior: 'smooth',
                         });
    }

    protected getOlder() {
        if (this.edit || this.isLoading) {
            return;
        }

        if (!this.canLoadOldMsg) {
            this.toPremiumForOlderMsg();
            return;
        }

        this.isLoading = true;
        this.messageService.loadOlder(this.conversationId, this.chatList.firstMessage.id)
            .then((cur) => {
                this.moreMessages = cur.data.moreMessages;
                this.chatList.addMessages(cur.data.messages);
                this.loadOlderCounter++;
                this.isLoading = false;
            });

    }

    private loadMessages() {
        this.isLoading = true;
        this.messageService
            .get(this.conversationId)
            .then((cur) => {
                this.moreMessages = cur.moreMessages;
                this.conversationRead = cur.conversationRead;

                const unreadCount = cur.messages.filter(it => !it.read).length;

                this.currentUserService.refreshBadgeCount();
                this.chatList.addMessages(cur.messages);

                if (this.unreadCounter === 0) {
                    setTimeout(() => {
                        if (!this.isSystemMsg) this.scroll();
                    }, 100);
                }

            }).finally(() => {
            this.isLoading = false;
        });
    }

    private poll() {
        if (!this.isPolling && !this.edit) {
            this.isPolling = true;

            const scrolledBot = this.scrolledToBottom;

            const lastReceivedMessage = this.chatList.lastReceivedMessage;
            const id = !!lastReceivedMessage ? lastReceivedMessage.id : 0;

            this.messageService
                .get(this.conversationId, id)
                .then((cur) => {
                    this.conversationRead = cur.conversationRead;
                    this.chatList.removeSentMessagesSuccessfully();

                    if (cur.messages.length > 0) {
                        this.unreadCounter += this.calcUnreadMsg(Message.createMultiple(cur.messages));
                        this.chatList.addMessages(cur.messages);
                    }
                    this.isPolling = false;

                    if (scrolledBot) setTimeout(this.scroll.bind(this));
                });
        }
    }

    private calcUnreadMsg(newMsg: Message[]) {
        return newMsg.filter((val: Message) => !val.isSender()).length;
    }

    protected get isSystemMsg(): boolean {
        return this.conversationId.split('-').length === 3;
    }

    protected get systemMsg(): Message {
        if (this.chatList.entries.length > 0) {
            const message = this.chatList.firstMessage;
            // TODO: API always sets refNumber to null in messages, so extract it from the conversationId and add it to the message
            message.refNumber = this.conversationRefNumber;
            return this.chatList.firstMessage;
        }

        return new Message();
    }

    protected openDeleteDialog() {
        this.deleteDialog.open();
    }

    protected get selectedMessages(): Array<ChatListMessageEntry> {
        return this.chatList.entries.filter(entry => entry.type === ChatListEntryType.Message ? (entry as unknown as ChatListSelectableEntry).selected : false)
                   .map(entry => entry as ChatListMessageEntry);
    }

    protected deleteMessages() {
        this.trackingService.hit('Konversation', 'Auswahl', 'NachrichtEinzelnLoeschen');

        this.edit = false;

        const entries = this.selectedMessages;
        this.chatList.deleteWhere(entry => entries.find(deletionEntry => deletionEntry === entry) !== undefined);

        this.messageService.delete(this.conversationId, entries.map(e => e.msg.id)).then(() => {
            this.communicationService.growl.message.delete.handleSuccess();
        }).catch(() => {
            this.communicationService.growl.message.delete.handleError();
        });
    }

    protected openSpamReport() {
        this.trackingService.hit('Konversation', 'Auswahl', 'VerstossMelden');
        this.edit = false;
        this.deleteDialog.close();
        this.spamReport.show();
    }

    protected entryTypes = ChatListEntryType;

    protected asMessageEntry(entry: ChatListEntry): ChatListMessageEntry {
        return entry as ChatListMessageEntry;
    }

    protected asSentMessageEntry(entry: ChatListEntry): ChatListSentMessageEntry {
        return entry as ChatListSentMessageEntry;
    }

    protected asDateHeaderEntry(entry: ChatListEntry): ChatListDateHeaderEntry {
        return entry as ChatListDateHeaderEntry;
    }

    protected toPremiumForOlderMsg() {
        this.premiumService.navigateToPremium(OrderReason.OlderMessages);
    }

    protected onSelectChanged(entry: ChatListEntry, event) {
        if (entry.type === ChatListEntryType.Message || entry.type === ChatListEntryType.SentMessage) {
            let messageEntry = entry as unknown as ChatListSelectableEntry;
            messageEntry.selected = !messageEntry.selected;
        }
    }

    // TODO improve handling of older message loading
    private isAtTop: boolean = false;
    private lastScrollTop: number = 0;

    private get canLoadOldMsg(): boolean {
        return this.loadOlderCounter < 2 || this.curUser.isPremium();
    }

    private get scrollTop(): number {
        return window.pageYOffset || (document.documentElement || document.body).scrollTop;
    }

}

export enum ChatListEntryType {
    Message,
    NewMessageHeader,
    DateHeader,
    OlderMessages,
    SentMessage,
    SentMessageSuccessfully
}

export interface ChatListEntry {
    readonly type: ChatListEntryType;
}

export interface ChatListSelectableEntry {
    selected: boolean
}

export class ChatListMessageEntry implements ChatListEntry, ChatListSelectableEntry {
    public get type(): ChatListEntryType {
        return ChatListEntryType.Message;
    }

    constructor(public msg: MessageInterface) {
    }

    public selected: boolean = false;
}

export class ChatListNewMessageHeaderEntry implements ChatListEntry {
    public get type(): ChatListEntryType {
        return ChatListEntryType.NewMessageHeader;
    }
}

export class ChatListDateHeaderEntry implements ChatListEntry {
    public get type(): ChatListEntryType {
        return ChatListEntryType.DateHeader;
    }

    // TODO find a working way to import the correct type
    constructor(public date = moment()) {
    }
}

export class ChatListOlderMessagesEntry implements ChatListEntry {
    public get type(): ChatListEntryType {
        return ChatListEntryType.OlderMessages;
    }
}

export class ChatListSentMessageEntry implements ChatListEntry, ChatListSelectableEntry {
    public get type(): ChatListEntryType {
        return ChatListEntryType.SentMessage;
    }

    constructor(public msg: MessageInterface) {
    }

    public error: boolean = false;
    public errorNumber: ErrorNumber = null;
    public selected: boolean = false;
}

export class ChatListSentMessageSuccessfullyEntry extends ChatListMessageEntry {
    public get type(): ChatListEntryType {
        return ChatListEntryType.SentMessageSuccessfully;
    }
}

class ChatList {
    public entries: Array<ChatListEntry> = [];

    public get firstMessage(): MessageInterface {
        for (var item of this.entries) {
            const message = ChatList.messageFromEntry(item);
            if (message !== null) return message;
        }
        return null;
    }

    public get lastReceivedMessage(): MessageInterface {
        const entry = this.entries.slice().reverse().find(it => it.type === ChatListEntryType.Message)

        if(!!entry) return (entry as ChatListMessageEntry).msg;
        return null;
    }

    public insert(entry: ChatListEntry, at: number) {
        if (at < this.entries.length) this.entries.splice(at, 0, entry);
        else this.entries.push(entry);
    }

    public deleteWhere(pred: (e: ChatListEntry) => boolean) {
        this.entries = this.entries.filter((value) => !pred(value));
    }

    public addMessages(messages: Array<MessageInterface>) {
        // Filter messages we already have
        messages.forEach((value, index, array) => {
            if (this.hasMessage(value)) return;

            let entry = new ChatListMessageEntry(value);
            let timestamp = moment.unix(entry.msg.sendtime).add(entry.msg.sendtimeMilliseconds, 'milliseconds');

            // Find insertion index
            let insertIndex = 0;
            let foundPosition = false;
            for (; insertIndex < this.entries.length && !foundPosition; insertIndex++) {
                switch (this.entries[insertIndex].type) {
                    case ChatListEntryType.OlderMessages:
                        continue;
                    case ChatListEntryType.NewMessageHeader:
                        continue;
                    case ChatListEntryType.DateHeader: {
                        let dateHeader = (this.entries[insertIndex] as ChatListDateHeaderEntry);
                        if (timestamp.isAfter(dateHeader.date)) continue;
                        foundPosition = true;
                        break;
                    }
                    case ChatListEntryType.Message: {
                        let messageEntry = (this.entries[insertIndex] as ChatListMessageEntry);
                        let messageEntryTimestamp = moment.unix(messageEntry.msg.sendtime).add(messageEntry.msg.sendtimeMilliseconds, 'milliseconds');
                        if (timestamp.isAfter(messageEntryTimestamp)) continue;
                        foundPosition = true;
                        break;
                    }
                    case ChatListEntryType.SentMessage:
                        // All messages are inserted before any sent message that wasn't replaced yet
                        foundPosition = true;
                        break;
                }

                // Prevent incrementing index
                if (foundPosition) break;
            }

            // Check previous item to determine if we need to insert a new date header
            let firstMessage = true;
            for (let i = insertIndex - 1; i >= 0; i--) {
                switch (this.entries[i].type) {
                    case ChatListEntryType.SentMessage:
                    case ChatListEntryType.DateHeader:
                    case ChatListEntryType.Message:
                        firstMessage = false;
                        break;
                }
            }
            let newElementDate = moment.unix(entry.msg.sendtime).startOf('day');
            if (insertIndex > 0 && this.entries[insertIndex - 1].type === ChatListEntryType.Message) {
                let prevMessage = this.entries[insertIndex - 1] as ChatListMessageEntry;
                let prevDate = moment.unix(prevMessage.msg.sendtime).startOf('day');

                if (newElementDate.isAfter(prevDate)) {
                    this.insert(new ChatListDateHeaderEntry(newElementDate), insertIndex++);
                }
            } else if (firstMessage) {
                this.insert(new ChatListDateHeaderEntry(newElementDate), insertIndex++);
            }

            // Check if the new message is the first unread message
            if (!entry.msg.read && entry.msg.senderId !== this.currentUser.id) {
                let prevMessage: ChatListMessageEntry = null;

                for (let i = insertIndex - 1; i > 0; i--) {
                    if (this.entries[i].type === ChatListEntryType.Message) {
                        let messageEntry = this.entries[i] as ChatListMessageEntry;
                        if (messageEntry.msg.senderId !== this.currentUser.id) {
                            prevMessage = this.entries[i] as ChatListMessageEntry;
                            break;
                        }
                    }
                }

                if (!this.entries.contains(it => it.type === ChatListEntryType.NewMessageHeader)) {
                    if (prevMessage === null || prevMessage.msg.read) {
                        this.insert(new ChatListNewMessageHeaderEntry(), insertIndex++);
                    }
                }
            }

            // Insert message
            this.insert(entry, insertIndex);
        });
    }

    public addSentMessage(message: MessageInterface): ChatListSentMessageEntry {
        let entry = new ChatListSentMessageEntry(message);

        this.entries.push(entry);

        return entry;
    }

    public transformSentMessage(sentMessage: ChatListSentMessageEntry) {
        this.entries.splice(this.entries.indexOf(sentMessage), 1, new ChatListSentMessageSuccessfullyEntry(sentMessage.msg));
    }

    public hasMessage(message: MessageInterface): boolean {
        for (let entry of this.entries) {
            if (entry.type === ChatListEntryType.Message && ChatList.messagesEqual(message, (entry as ChatListMessageEntry).msg)) return true;
        }

        return false;
    }

    public getMessagesWithError(): Array<ChatListSentMessageEntry> {
        return this.entries
                   .filter(it => it.type === ChatListEntryType.SentMessage)
                   .filter((it: ChatListSentMessageEntry) => it.error) as Array<ChatListSentMessageEntry>;
    }

    public removeMessagesWithError() {
        this.entries = this.entries.filter(it => {
            if (it.type !== ChatListEntryType.SentMessage) return true;
            return !(it as ChatListSentMessageEntry).error;
        });
    }

    public removeSentMessagesSuccessfully() {
        this.entries = this.entries.filter(it => it.type !== ChatListEntryType.SentMessageSuccessfully);
    }

    private static messagesEqual(a: MessageInterface, b: MessageInterface) {
        return a.id === b.id && a.sendtime === b.sendtime && a.sendtimeMilliseconds === b.sendtimeMilliseconds;
    }

    public before(msg: MessageInterface): MessageInterface {
        // TODO Might be improved by using a decorator adding the index or implementing a linked list
        let previousMsg: MessageInterface = null;
        for (let entry of this.entries) {
            const msgFromEntry = ChatList.messageFromEntry(entry);
            if (msgFromEntry === null) continue;
            if (ChatList.messagesEqual(msgFromEntry, msg)) break;
            previousMsg = msgFromEntry;
        }
        return previousMsg;
    }

    private static messageFromEntry(entry: ChatListEntry): MessageInterface {
        if (entry.type === ChatListEntryType.Message) return (entry as ChatListMessageEntry).msg;
        if (entry.type === ChatListEntryType.SentMessage) return (entry as ChatListSentMessageEntry).msg;
        if (entry.type === ChatListEntryType.SentMessageSuccessfully) return (entry as ChatListSentMessageSuccessfullyEntry).msg;
        return null;
    }

    constructor(public currentUser: CurrentUser) {
    }


}

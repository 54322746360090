import {
    Injector,
    Type,
} from '@angular/core';
import { Online } from 'BKModels';


export const lockBody = () => {
    document.body.classList.add('noscroll');

    // document.body.addEventListener('touchmove', preventDefault, false);
};

export const unLockBody = () => {
    document.body.classList.remove('noscroll');

    // document.body.removeEventListener('touchmove', preventDefault, false);
};


/**
 * Disable scrolling of the body
 {boolean} flag
 */
export function setBodyNoScroll(flag: boolean) {
    if (flag) lockBody(); else unLockBody();
}

/**
 * function for parse the language
 {string} val
 {string}
 */
export function parseLang(val: string): string {
    if (val.length === 0) {
        return val;
    }
    if (val === 'key') return val;

    if (val.indexOf('-') !== -1) {
        return val.toLowerCase()
                  .split('-')
                  .map((cur, i) => (i === 0 ? cur : cur.toUpperCase()))
                  .join('-');
    }
    return `${val}-${val.toUpperCase()}`;
}

export const isDevMode: boolean = (() => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')();
export const isBetaMode: boolean = (() => process.env.NODE_ENV === 'beta')();


export function uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        /*eslint-disable*/
        const r = Math.random() * 16 | 0;
        const v = c == 'x' ? r : (r & 0x3 | 0x8);
        /* eslint-enable */

        return v.toString(16);
    });
}

export class ScrollContainer {
    public get scrollHeight() {
        return Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight,
        );
    }

    public get scrollWidth() {
        return Math.max(
            document.body.scrollWidth, document.documentElement.scrollWidth,
            document.body.offsetWidth, document.documentElement.offsetWidth,
            document.body.clientWidth, document.documentElement.clientWidth,
        );
    }

    public get scrollTop() {
        return window.scrollY;
    }

    public set scrollTop(val) {
        window.scrollTo(window.scrollX, val);
    }

    public get scrollLeft() {
        return window.screenX;
    }

    public set scrollLeft(val) {
        window.scrollTo(val, window.scrollY);
    }


    public get offsetHeight() {
        return window.pageYOffset;
    }

    public get offsetWidth() {
        return window.pageXOffset;
    }

    public scroll(options: ScrollToOptions) {
        window.scroll(options);
    }

    public addEventListener(type, listener) {
        window.addEventListener(type, listener);
    }

    public removeEventListener(type, listener) {
        window.removeEventListener(type, listener);
    }

    public get containerHeight() {
        return window.innerHeight;
    }
}

const scrollContainer = new ScrollContainer();

export function getScrollContainer(): ScrollContainer {
    return scrollContainer;
}

export function replaceTextSmileysByEmoji(input: string): string {
    const smileyAliases = [
        { emoji: '😀', aliases: [':)', ':-)', ':o)'] },
        { emoji: '😉', aliases: [';)', ';-)', ';o)'] },
        { emoji: '😁', aliases: [':D', ':-D'] },
        { emoji: '😐', aliases: [':|', ':-|', ':o|'] },
        { emoji: '😮', aliases: [':o', ':-o'] },
        { emoji: '😟', aliases: [':(', ':-(', ':o('] },
        { emoji: '😎', aliases: ['8)', '8-)', '8o)'] },
        { emoji: '😛', aliases: [':P', ':-P', ':oP', ':p', ':-p'] },
    ];

    smileyAliases.forEach((entry) => {
        entry.aliases.forEach((alias) => {
            input = input.replace(alias, entry.emoji);
        });
    });

    return input;
}

export function mapCountryCodeToFlagEmoji(input: string): string {
    const map = {
        'A': '🇦🇹',
        'CH': '🇨🇭',
        'D': '🇩🇪',
        'PL': '🇵🇱'
    };

    if (input in map) return map[input];
    else return input;
}

export function translationKeyForOnline(online: Online) {
    switch (online) {
        case Online.Offline:
            return 'ONLINE_STATE.OFFLINE';
        case Online.Online:
            return 'ONLINE_STATE.ONLINE';
        case Online.Away:
            return 'ONLINE_STATE.AWAY';
        case Online.MobilOnline:
            return 'ONLINE_STATE.MOBILE_ONLINE';
        case Online.MobilAway:
            return 'ONLINE_STATE.MOBILE_AWAY';
        case Online.RecentlyOnline:
            return 'ONLINE_STATE.RECENTLY_ONLINE';
        default:
            return '';
    }
}

export function startLoadingBar() {
    document.dispatchEvent(new Event('customLoading'));
}

export function stopLoadingBar() {
    document.dispatchEvent(new Event('customLoadingFinished'));
}

export function dispatchLogoutEvent() {
    document.dispatchEvent(new Event('logout'));
}

export function addLogoutEventListener(cb: () => void) {
    document.addEventListener('logout', cb);
}

export function dispatchForcedLogoutEvent() {
    document.dispatchEvent(new Event('forcedLogout'));
}

export function addForcedLogoutEventListener(cb: () => void) {
    document.addEventListener('forcedLogout', cb);
}

export function dispatchLoginEvent() {
    document.dispatchEvent(new Event('login'));
}

export function addLoginEventListener(cb: () => void) {
    document.addEventListener('login', cb);
}

export function addNavigationFinishedListener(cb: () => void) {
    document.addEventListener('navigationFinished', cb);
}

export function removeNavigationFinishedListener(cb: () => void) {
    document.removeEventListener('navigationFinished', cb);
}

export function dispatchNavigationFinishedEvent() {
    document.dispatchEvent(new Event('navigationFinished'));
}

export const formatAmount = amount => {
    return Intl.NumberFormat('de-DE', {
        style:           'currency',
        currency:        'EUR',
        currencyDisplay: 'symbol',
    }).format(amount);
};

export function formatIban(iban: string, fallback: string = iban): string {
    const matches = iban.toUpperCase().replace(/\s/g, '').match(/.{1,4}/g);
    let formatedIban = fallback;
    if (matches) formatedIban = matches.join(' ');

    return formatedIban;
}

export function FormatAmount() {
    return function (...args: any[]) {
        const argsLength = args.filter(cur => cur !== null && cur !== undefined).length;

        if (argsLength === 2) return formatAmountProperty.apply(this, args);
        if (argsLength === 3) {
            if (args[2].hasOwnProperty('get') || args[2].hasOwnProperty('set')) return formatAmountAccessor.apply(this, args);
            return formatAmountMethod.apply(this, args);
        }

    };

    function formatAmountMethod(target, key, descriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = function (...parmas) {
            const result = originalMethod.apply(this, parmas);
            return isNaN(result)? result : formatAmount(result);
        };
    }

    function formatAmountProperty(target: any, key: string) {
        if (delete this[key]) Object.defineProperty(target, key, {
            enumerable:   true,
            configurable: true,
            get:          () =>  isNaN(this[key])? this[key] : formatAmount(this[key]),
            set:          (newValue) => this[key] = newValue,
        });
    }

    function formatAmountAccessor(target, key, descriptor) {
        if (descriptor === undefined) descriptor = Object.getOwnPropertyDescriptor(target, key);
        const originalGet = descriptor.get;
        if (originalGet) {
            descriptor.get = function () {
                const result = originalGet.apply(this);
                return isNaN(result)? result : formatAmount(result);
            };
        }

        if (delete this[key]) Object.defineProperty(target, key, descriptor);
    }
}

export function openRegistration() {
    window.location.href = 'https://www.bildkontakte.de/vlp-a/';
}

export function generateEpcString(value: string, recipient: string, iban: string, bic: string, reference: string = '', isCreditorReference: boolean = false, info: string  = ''): string {
    return `BCD\n002\n2\nSCT\n${bic}\n${recipient}\n${iban}\n${value}\n\n${isCreditorReference ? reference : ''}\n${!isCreditorReference ? reference : ''}\n${info}`;
}

export class DynamicallyInjected<T> {
    private injectable: T = null;

    get get(): T {
        if (!this.injectable) this.injectable = this.injector.get<T>(this.type);
        return this.injectable;
    }

    constructor(private type: Type<T>, private injector: Injector) {}
}

export function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    Settings,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    SettingsService,
    StoreService,
} from 'BKService';
import { Subject } from 'rxjs';
import {
    first,
    takeUntil,
} from 'rxjs/operators';
import {
    Offer,
    OfferType,
} from '../../../models/offer';
import { GooglePlayPaymentsService } from '../../../service/google-play-payments.service';
import { HeaderService } from '../../../service/header.service';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';
import { OfferService } from '../../../service/offer.service';
import { PushNotificationService } from '../../../service/push-notification.service';
import { DataLayerService } from '../../../service/tracking/dataLayer.service';
import { DialogBox } from '../../../shared/mat-design/dialog/dialog-box';
import { OfferModal } from '../../../shared/mat-design/offers/offer-modal';
import { Header } from './header/header';

/**
 * Komponente für das Grundsätzliche Layout
 * Sprich ProfileHeader, Seitennavigation sowie Modals die auf jeder Seite
 * angezeigt werden können wie zum Beispiel die Profileansicht
 *
 */
@Component({
               selector:  'layout',
               template: `
                             <div class="layout">
                                 <profile-view-modal></profile-view-modal>
                                 <bk-header #header [incognito]="settings.anonymous"></bk-header>

                                     <section id="left-section" class="layout__left-section"
                                              [style.min-height.px]="sectionHeight"
                                              [style.max-height.px]="sectionHeight"
                                     >
                                         <!-- empty -->
                                     </section>
                                     <section id="bk-section" class="layout__section"
                                              [style.min-height.px]="sectionHeight"
                                              [style.max-height.px]="sectionHeight"
                                     >
                                         <div class="layout__section__scroll">
                                             <div class="layout__section__wrapper">
                                                 <bk-side-nav [badgeCounts]="curUserService.badgeCountsObservable | async"
                                                              [(open)]="isSideNavOpen"
                                                              [headerHeight]="header.height"
                                                 >
                                                 </bk-side-nav>
                                                 <div id="bk-content"
                                                      class="layout__section__content"
                                                 >
                                                     <router-outlet></router-outlet>
                                                 </div>
                                             </div>
                                         </div>
                                     </section>
                                     <section id="right-section" class="layout__right-section"
                                              [style.min-height.px]="sectionHeight"
                                              [style.max-height.px]="sectionHeight"
                                     >
                                         <profile-visits-preview></profile-visits-preview>
                                         <ad-slot slotName="BkRl_right-nav"></ad-slot>
                                         <card class="layout__right-section__card">
                                             <div class="layout__right-section__card__container">
                                                 <active-users-preview></active-users-preview>
                                             </div>
                                         </card>
                                     </section>
                                 </div>

                                 <bk-modal-global-collection></bk-modal-global-collection>
                                 <ng-container *ngIf="showOffers">
                                     
                                     <ng-container *ngFor="let offer of offersWithModal">
                                         <offer-modal #offerModal
                                                      [offer]="offer"
                                                      [username]="(curUserService.currentUserObservable | async).nickname"
                                                      [closeIcon]="true"
                                         ></offer-modal>
                                     </ng-container>
                                 </ng-container>

                                 <dialog-box #pushNotificationDialog [title]="'MODAL.PUSH.TITLE' | translate"
                                             [rejectText]="'MODAL.PUSH.CLOSE' | translate"
                                             [acceptText]="'MODAL.PUSH.ENABLE' | translate"
                                             (onAccept)="enablePushes()"
                                             (onReject)="askForPushesLater()"
                                 >
                                     <div [innerHTML]="'MODAL.PUSH.CONTENT' | translate | markdown"></div>
                                 </dialog-box>
                                <div *ngIf="showPushPermissionHint" class="push-permission-hint">
                                    <div class="push-permission-hint-title">
                                        <img src="./assets/img/iconArrowPush.svg">
                                        <div [innerHTML]="'PERMISSION_HINT.PUSHES.TITLE'|translate" class="push-permission-hint-title-text"></div>
                                    </div>
                                    <div [innerHTML]="'PERMISSION_HINT.PUSHES.TEXT'|translate|markdown" class="markdown p-nomargin push-permission-hint-text"></div>
                                    <raised-button (click)="showPushPermissionHint = false">{{ 'PERMISSION_HINT.PUSHES.ACTION'|translate }}</raised-button>
                                </div>

                             <communication></communication>
                             <quick-nav></quick-nav>
                         `,
               styles: [require('./layout.scss')],
           })
export class Layout implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('header', { static: true }) private header: Header;
    @ViewChild('pushNotificationDialog', { static: true }) private pushNotificationDialog: DialogBox;
    @ViewChildren('offerModal') private offerModals: QueryList<OfferModal>;
    private unsubscribe$ = new Subject();
    settings: Settings = new Settings();
    offers: Offer[];

    protected showOffers = false;

    private _isSideNavOpen: boolean = false;

    protected get isSideNavOpen(): boolean {
        return this._isSideNavOpen;
    }

    protected set isSideNavOpen(val: boolean) {
        this.headerService.setSideNavOpen(val);
    }

    protected get sectionHeight() {
        return window.innerHeight - this.header.height;
    }

    protected showPushPermissionHint = false;

    public constructor(
        public curUserService: CurrentUserService,
        private settingsService: SettingsService,
        private matchingService: MatchingService,
        private headerService: HeaderService,
        private offerService: OfferService,
        protected storeService: StoreService,
        protected pushNotificationService: PushNotificationService,
        protected googlePlayPaymentsService: GooglePlayPaymentsService,
        protected dataLayerService: DataLayerService,
        private navigationService: NavigationService,
    ) {
        this.googlePlayPaymentsService.hasStore().then(hasStore => {
            this.showOffers = !hasStore;
            this.dataLayerService.setHasGooglePlayPayments(hasStore);
        });
    }

    public ngOnInit() {
        this.headerService
            .sideNavOpen
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(it => this._isSideNavOpen = it);

        this.offerService.loadOffer().then(offers => {
            this.offers = offers;
            this.openOfferModal();
        });

        this.pushNotificationService.pushesAvailable$.pipe(first()).subscribe(available => {
            if (!available) return;
            if (!this.storeService.pushesRejected.getValues()) {
                this.pushNotificationService.hasSubscription().then(v => {
                    if (v) this.pushNotificationService.updateSubscriptionOnBackend();
                    else if (!this.pushNotificationService.permissionGranted) this.pushNotificationDialog.open();
                });
            }
        });
    }

    protected enablePushes() {
        this.showPushPermissionHint = true;
        this.pushNotificationService.enable()
            .then(_ => this.showPushPermissionHint = false)
            .catch(_ => this.showPushPermissionHint = false);
    }

    protected askForPushesLater() {
        this.storeService.pushesRejected.next(true);
    }

    public ngAfterViewInit() {
        setTimeout(() => this.curUserService.startRefreshInterval(), 200);

        this.settingsService.settings.pipe(takeUntil(this.unsubscribe$)).subscribe(settings => this.settings = settings);

        this.matchingService.reload();

        this.curUserService.currentUserObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(currentUser => {
            if (!currentUser.emailValidated) {
                this.navigationService.navigateTo(Routes.RegCode);
            }
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private openOfferModal() {
        setTimeout(() => {
            let modalToShow: OfferModal;

            for (let modal of this.offerModals.toArray()) {
                // TODO: Add other offer modals and use only the highest priority one
                modalToShow = modal;
            }

            if (modalToShow) {
                modalToShow.open();
            }
        });
    }
    get offersWithModal(): Offer[] {
        if( !this.offers ) return [];

        return this.offers.filter((offer: Offer) => {
            return offer.offerType === OfferType.LegacyWelcomeOffer
                || offer.offerType === OfferType.LegacyWelcomeDelayedOffer
                || offer.offerType === OfferType.LoyaltyOffer
                || offer.offerType === OfferType.BirthdayOffer;
        });
    }

}

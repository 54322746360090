import { Injectable } from '@angular/core';
import { SHA3Hash } from 'sha3';

@Injectable({ providedIn: 'root' })
export class DataLayerService {
    private static get dataLayer() {
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer;
    }

    private _userId: number = 0;
    private _hashedUserId: string = '';
    private _hasGooglePlayPayments = false;

    public get userId(): number {
        return this._userId;
    }

    public set userId(userId: number) {
        this._userId = userId;
        this._hashedUserId = this.hash(`dluid:${userId}`);
        this.push({
                      event:      'UserIdReady',
                      eventValue: this._hashedUserId,
                      userId:     this._hashedUserId,
                  });
    }

    setHasGooglePlayPayments(v: boolean) {
        this._hasGooglePlayPayments = v;
        this.push({
                      event:      'HaveGooglePlayPaymentsService',
                      eventValue: this._hasGooglePlayPayments,
                  });
    }

    public push(item: object) {
        DataLayerService.dataLayer.push(item);
    }

    private hash(input: string, encoding = 'base64'): string {
        const hasher = new SHA3Hash(256);
        hasher.update(input);
        return hasher.digest().toString(encoding);
    }
}

import { TranslateService } from '@ngx-translate/core';
import {
    ErrorNumber,
    Growl,
    GrowlType,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import {
    CommunicationBase,
    GrowlCreator,
} from './communication.base';


export class GrowlCommunication extends CommunicationBase<Growl> implements GrowlCreator {
    public message: MessagesGrowlTemplate = new MessagesGrowlTemplate(this, this.translate);

    public image = {
        upload: {
            handleSuccess: () => this.positive(this.translate.instant('EDIT-IMAGE.UPLOAD_IMAGE.SUCCESS')),
            handleError:   () => this.negative(this.translate.instant('EDIT-IMAGE.UPLOAD_IMAGE.ERROR')),
        },
    };

    public payment = {
        changeDebit: {
            handleSuccess: () => this.positive(this.translate.instant('SETTINGS.CHANGE-PAYMENT-DATA.SUCCESSFULL')),
            handleError:   () => this.negative(this.translate.instant('SETTINGS.CHANGE-PAYMENT-DATA.FAIL')),
        },
        repay: {
            handleSuccess: () => this.positive(this.translate.instant('SETTINGS.REPAY.SUCCESSFULL')),
            handleError:   () => this.negative(this.translate.instant('SETTINGS.REPAY.FAIL')),
        }
    };

    public settings = {
        changePassword: {
            handleSuccess: () => this.positive(this.translate.instant('SETTINGS.PASSWORD_CHANGE.SUCCESSFUL')),
            handleError:   () => this.negative(this.translate.instant('SETTINGS.PASSWORD_CHANGE.FAIL')),
        },
        changeSettings: {
            handleSuccess: () => this.positive(this.translate.instant('SETTINGS.SETTINGS_CHANGE.SUCCESSFUL')),
            handleError:   () => this.negative(this.translate.instant('SETTINGS.SETTINGS_CHANGE.FAIL')),
        },
        emailSettings: {
            handleError: () => this.negative(this.translate.instant('SETTINGS.EMAIL_SETTINGS_CHANGE.ERROR')),
            success: () => this.positive(this.translate.instant('SETTINGS.EMAIL_SETTINGS_CHANGE.SUCCESS')),
        }
    };

    public ignore = {
        add: {
            handleSuccess: (user: UserBaseView) => this.positive(this.translate.instant('SNACKBAR.IGNORE.ADD', {nickname: user.nickname})),
            handleError:   (user: UserBaseView) => this.negative(this.translate.instant('SNACKBAR.IGNORE.ADD.ERROR', {nickname: user.nickname})),
        },
        remove: {
            handleSuccess: (user: UserBaseView) => this.positive(this.translate.instant('SNACKBAR.IGNORE.REMOVE', {nickname: user.nickname})),
            handleError:   (user: UserBaseView) => this.negative(this.translate.instant('SNACKBAR.IGNORE.REMOVE.ERROR', {nickname: user.nickname})),
        }
    };

    public regCode = {
        resend: {
            handleSuccess: (email: string) => this.positive(this.translate.instant('REGCODE.SEND_AGAIN.SUCCESS', {email: email}),
                                                            this.translate.instant('REGCODE.SEND_AGAIN.SUCCESS_TITLE'))
        }
    }

    public stayOnBeta = {
        handleError:   () => this.negative(this.translate.instant('STAY_ON_BETA.ERROR')),
    };

    public constructor(private translate: TranslateService) {
        super();
    }

    public info(text: string, title: string) {
        this.add(new Growl(GrowlType.Info, title, text));
    }

    public negative(text: string, title: string = this.negativeGrowlDefaultTitle) {
        this.add(new Growl(GrowlType.Negative, title, text));
    }

    public positive(text: string, title: string = this.positiveGrowlDefaultTitle) {
        this.add(new Growl(GrowlType.Positive, title, text));
    }

    public warning(text: string, title: string) {
        this.add(new Growl(GrowlType.Warning, title, text));
    }

    private get negativeGrowlDefaultTitle() {
        return this.translate.instant('SNACKBAR.NEGATIVE');
    }

    private get positiveGrowlDefaultTitle() {
        return this.translate.instant('SNACKBAR.POSITIVE');
    }
}

class GrowlTemplate {
    public constructor(protected growlCreator: GrowlCreator, protected translate: TranslateService) {
    }
}

class MessagesGrowlTemplate extends GrowlTemplate {
    public send = {
        handleSuccess: (partner) => this.growlCreator.positive(this.translate.instant('CHAT.MESSAGE.SEND.SUCCESSFULL', partner)),
        handleError:   (errorNumber: ErrorNumber, partner: ProfileInfo) => {
            const errorMsgMap = {
                [ErrorNumber.IGNORE_USER_IGNORES_YOU]:          'CHAT.MESSAGE.ERROR.IGNORES_YOU',
                [ErrorNumber.IGNORE_YOU_IGNORE_USER]:           'CHAT.MESSAGE.ERROR.YOU_IGNORE',
                [ErrorNumber.MESSAGE_RECEIVING_DISABLED]:       'CHAT.MESSAGE.ERROR.DISABLE_CONTACT',
                [ErrorNumber.MESSAGE_CONTINGENT_LIMIT_REACHED]: 'CHAT.MESSAGE.ERROR.CONTINGENT_REACHED',
                [ErrorNumber.GENERAL_PREM_NEED]:                'CHAT.MESSAGE.ERROR.PREM_NEED',
                [ErrorNumber.GENERAL_USER_HALF_LOCKED]:         'CHAT.MESSAGE.ERROR.USER_HALF_LOCKED',
                [ErrorNumber.GENERAL_USER_LOCKED]:              'CHAT.MESSAGE.ERROR.USER_LOCKED',
            };
            const errorText = errorMsgMap[errorNumber] || 'CHAT.MESSAGE.ERROR';
            this.growlCreator.negative(this.translate.instant(errorText, partner));
        },
    };

    public delete = {
        handleSuccess: () => this.growlCreator.positive(this.translate.instant('CHAT.MESSAGES.DELETE.SUCCESSFULL')),
        handleError: () => this.growlCreator.negative(this.translate.instant('CHAT.MESSAGES.DELETE.ERROR')),
    };

    public deleteConversation = {
        handleError: () => this.growlCreator.negative(this.translate.instant('CHAT.MESSAGES.DELETE_CONVERSATION.ERROR')),
    };
}

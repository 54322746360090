import {
    AfterViewInit,
    Component,
    Input,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
    PushSettings,
    SettingsChangeBase,
} from 'BKModels';
import { first } from 'rxjs/operators';
import { PremiumService } from '../../../../service/premium.service';
import { PushNotificationService } from '../../../../service/push-notification.service';

@Component({
               selector:  'settings-notification-push',
               template: `
                             <settings-title>{{'SETTINGS.NOTIFICATION.PUSH.TITLE' | translate}}</settings-title>
                             <ng-container *ngIf="!hide">
                                 <settings-switch [title]="'SETTINGS.NOTIFICATION.PUSH.ENABLE' | translate" [(value)]="pushesEnabled"></settings-switch>
    
                                 <settings-switch [disabled]="!pushesEnabled" [title]="'SETTINGS.NOTIFICATION.PUSH.MATCH'|translate" [(value)]="settings.match"></settings-switch>
                                 <settings-switch [disabled]="!pushesEnabled" [title]="'SETTINGS.NOTIFICATION.PUSH.MESSAGE'|translate" [(value)]="settings.message"></settings-switch>
                                 <settings-switch [disabled]="!pushesEnabled" [title]="'SETTINGS.NOTIFICATION.PUSH.OPEN_MATCH'|translate" [(value)]="settings.openMatch"></settings-switch>
                                 <settings-switch [disabled]="!pushesEnabled" 
                                                  [premiumFunction]="true"
                                                  [userIsPremium]="isPremium"
                                                  [orderReason]="OrderReason.FavoriteLogin"
                                                  [title]="'SETTINGS.NOTIFICATION.PUSH.FAVORITE_LOGIN'|translate" 
                                                  [(value)]="settings.loginFavorite"></settings-switch>
                                 <settings-switch [disabled]="!pushesEnabled" [title]="'SETTINGS.NOTIFICATION.PUSH.PROFILE_VISITORS'|translate" [(value)]="settings.profileVisitor"></settings-switch>
    
                                 <div class="footer">
                                     <raised-button (click)="save.emit($event)">{{'SETTINGS.NOTIFICATION.EMAIL.SAVE' | translate}}</raised-button>
                                 </div>
                             </ng-container>
                         `,
               styles: [require('./settings-notification.scss')],
           })

export class SettingsNotificationPush extends SettingsChangeBase<PushSettings> implements AfterViewInit {
    @Input() protected currentUser: CurrentUser = null;

    protected readonly OrderReason = OrderReason;
    protected hide = true;
    protected _pushesEnabled = false;
    protected get pushesEnabled(): boolean {
        return this._pushesEnabled;
    }

    protected set pushesEnabled(v: boolean) {
        if (v) this.pushNotificationService.enable().then(_ => this._pushesEnabled = true);
        else this.pushNotificationService.disable().then(_ => this._pushesEnabled = false);
    }

    protected get isPremium(): boolean {
        return this.currentUser && this.currentUser.isPremium();
    }

    public ngAfterViewInit(): void {
        this.pushNotificationService.enabledState$.pipe(first()).subscribe(value => {
            this._pushesEnabled = value;
            this.hide = false;
        });
    }

    constructor(
        private pushNotificationService: PushNotificationService,
    ) {
        super();
    }
}

export enum ErrorNumber {
    GENERAL_FORBIDDEN = 403,
    GENERAL_MAINTENANCE = 10000,
    GENERAL_UNKNOWN_ERROR = 10001,
    GENERAL_NOT_AUTH = 10002,
    GENERAL_NO_SUBJECT_ID = 10003,
    GENERAL_USER_LOCKED = 10004,
    GENERAL_USER_HALF_LOCKED = 10005,
    GENERAL_PREM_NEED = 10006,
    GENERAL_INVALID_ARGUMENT = 10007,
    GENERAL_NO_PARAMS_SUPPLIED = 10008,
    GENERAL_USER_NOT_EXISTS = 10009,
    GENERAL_LIMIT_EXCEEDED = 10010,
    GENERAL_INVALID_TARGET = 10011,
    GENERAL_AUTH_NOT_SUFFICIENT_ROLE = 10012,
    GENERAL_BAD_CREDENTIALS = 10013,
    USERINFO_USER_NOT_FOUND = 11001,
    FRIENDS_ALREADY_FRIENDS = 12001,
    FRIENDS_ALREADY_SENT_OFFER = 12002,
    FRIENDS_NO_OFFER_FOUND = 12004,
    FRIENDS_NO_FRIENDSHIP_FOUND = 12005,
    IGNORE_USER_IGNORES_YOU = 13001,
    IGNORE_YOU_IGNORE_USER = 13002,
    MESSAGE_INVALID_CONVERSATION_ID = 14001,
    MESSAGE_RECEIVING_DISABLED = 14002,
    MESSAGE_CONTINGENT_LIMIT_REACHED = 14006,
    CREDIT_SYSTEM_UNLOCK_PRICE_DIFFERENCE = 15001,
    USER_IMAGE_UPLOAD_QUOTA_EXHAUST = 16001,
    USER_IMAGE_NO_MAIN_ALTERNATIVE = 16002,
    REGISTRATION_INVALID_REGISTRATION_ID = 17001,
    REGISTRATION_MOVE_TO_USER_FAILED = 17002,
    REGISTRATION_ALREADY_COMPLETED = 17003,
    PASSWORD_REST_NO_EMAIL = 18001,
    FAVORITE_ALREADY_ADDED = 19001,
    APPLE_APNID_ALREADY_ASSIGNED = 20001,
    APPLE_APNID_UNKNOWN = 20002,
    APPLE_APNID_ALREADY_REGISTERED = 20003,
    MATCHING_MAINTENANCE = 21000,
    MICROTRANS_INVALID_ITEM_TYPE = 22001,
    PUSH_NOTIFICATION_SERVER_NOT_REACHABLE = 23000,
    PUSH_NOTIFICATION_INVALID_REQUEST = 23001,
    PUSH_NOTIFICATION_UNKNOWN_OPERATING_SYSTEM = 23001,
    VALIDATION_ID_CARD_ALREADY_UPLOADED = 24001,
    VALIDATION_PHOTOCHECK_ALREADY_UPLOADED = 24002,
    EMAIL_ALREADY_VALIDATED = 30001

}


export enum PremiumError {
    TariffUnnkown = 26002,
    PaymentNotAllowed = 26003,
    UnprocessedDebit = 26005,
    OpenBill = 26006,

}

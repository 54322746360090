import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { GlobalModalService } from '../../../service/global-modal.service';

@Component({
            selector: 'bk-modal-global-collection',
            template: `
                          <ng-container>
                              <bk-modal-profile-picture-message #modalProfilePictureMessage></bk-modal-profile-picture-message>
                              <bk-modal-schlagerherzen-application #schlagerherzenApplication></bk-modal-schlagerherzen-application>
                              <bk-modal-schlagerherzen-success #schlagerherzenSuccess></bk-modal-schlagerherzen-success>
                              <bk-modal-schlagerherzen-error #schlagerherzenError></bk-modal-schlagerherzen-error>
                              <bk-modal-return-to-legacy #modalReturnToLegacy></bk-modal-return-to-legacy>
                              <bk-modal-reg-code #modalRegCode></bk-modal-reg-code>
                              <bk-modal-removed-ignored-user #modalRemovedIgnoredUser></bk-modal-removed-ignored-user>
                              <bk-modal-chargeback-payment-failed #modalChargebackPaymentFailed></bk-modal-chargeback-payment-failed>
                              <bk-modal-write-message #writeMessage></bk-modal-write-message>
                              <bk-modal-match #match></bk-modal-match>
                              <bk-nps #nps></bk-nps>
                              <bk-missed-chance-matching #missedChance></bk-missed-chance-matching>
                              <bk-premium-modal #premiumModal></bk-premium-modal>
                              <bk-modal-premium-bought #premiumBoughtModal></bk-modal-premium-bought>
                              <bk-modal-premium-payment-error #premiumPaymentError></bk-modal-premium-payment-error>
                              <bk-modal-become-vip #becomeVip></bk-modal-become-vip>
                              <bk-modal-email-reminder #emailReminder></bk-modal-email-reminder>
                              <bk-modal-email-change #emailChange></bk-modal-email-change>
                              <bk-modal-email-confirm #emailConfirm></bk-modal-email-confirm>
                          </ng-container>
                      `,
           })
export class BkModalGlobalCollection implements OnInit, OnDestroy {
    @ViewChild('modalProfilePictureMessage', { static: true }) private profilePictureMessage;
    @ViewChild('schlagerherzenApplication', { static: true }) private schlagerherzenApplication;
    @ViewChild('schlagerherzenSuccess', { static: true }) private schlagerherzenSuccess;
    @ViewChild('schlagerherzenError', { static: true }) private schlagerherzenError;
    @ViewChild('modalRegCode', { static: true }) private regCode;
    @ViewChild('modalRemovedIgnoredUser', { static: true }) private modalRemovedIgnoredUser;
    @ViewChild('modalReturnToLegacy', { static: true }) private returnToLegacy;
    @ViewChild('modalChargebackPaymentFailed', { static: true }) private chargebackPaymentFailed;
    @ViewChild('writeMessage', { static: true }) private writeMessage;
    @ViewChild('match', { static: true }) private match;
    @ViewChild('nps', { static: true }) private nps;
    @ViewChild('missedChance', { static: true }) private missedChance;
    @ViewChild('premiumModal', { static: true }) private premiumModal;
    @ViewChild('premiumBoughtModal', { static: true }) private premiumBoughtModal;
    @ViewChild('premiumPaymentError', { static: true }) private premiumPaymentError;
    @ViewChild('becomeVip', { static: true }) private becomeVip;
    @ViewChild('emailReminder', { static: true }) private emailReminder;
    @ViewChild('emailChange', { static: true }) private emailChange;
    @ViewChild('emailConfirm', { static: true }) private emailConfirm;

    constructor(private modalService: GlobalModalService) {
    }

    public ngOnInit(): void {
        this.modalService.add(this.profilePictureMessage);
        this.modalService.add(this.schlagerherzenApplication);
        this.modalService.add(this.schlagerherzenSuccess);
        this.modalService.add(this.schlagerherzenError);
        this.modalService.add(this.regCode);
        this.modalService.add(this.modalRemovedIgnoredUser);
        this.modalService.add(this.returnToLegacy);
        this.modalService.add(this.chargebackPaymentFailed);
        this.modalService.add(this.writeMessage);
        this.modalService.add(this.match);
        this.modalService.add(this.nps);
        this.modalService.add(this.missedChance);
        this.modalService.add(this.premiumModal);
        this.modalService.add(this.premiumBoughtModal);
        this.modalService.add(this.premiumPaymentError);
        this.modalService.add(this.becomeVip);
        this.modalService.add(this.emailReminder);
        this.modalService.add(this.emailChange);
        this.modalService.add(this.emailConfirm);
    }

    public ngOnDestroy(): void {
        this.modalService.remove(this.profilePictureMessage);
        this.modalService.remove(this.schlagerherzenApplication);
        this.modalService.remove(this.schlagerherzenSuccess);
        this.modalService.remove(this.schlagerherzenError);
        this.modalService.remove(this.regCode);
        this.modalService.remove(this.modalRemovedIgnoredUser);
        this.modalService.remove(this.returnToLegacy);
        this.modalService.remove(this.chargebackPaymentFailed);
        this.modalService.remove(this.writeMessage);
        this.modalService.remove(this.match);
        this.modalService.remove(this.nps);
        this.modalService.remove(this.premiumModal);
        this.modalService.remove(this.premiumBoughtModal);
        this.modalService.remove(this.premiumPaymentError);
        this.modalService.remove(this.becomeVip);
        this.modalService.remove(this.emailReminder);
        this.modalService.remove(this.emailChange);
        this.modalService.remove(this.emailConfirm);
    }

}

import {
    Notification,
    NotificationStyle,
    NotificationType,
} from './Notification';

export class MessageSystemMaintenanceNotification extends Notification {
    public notificationId = 'b61f6f57-633e-40b7-939b-e6db55b8d515';

    public types = NotificationType.StartPage;
    public style = NotificationStyle.Warning;
    public importance = 10000;

    public contentKey = 'NOTIFICATION.MESSAGE_SYSTEM_MAINTENANCE.TEXT';
    public titleKey = 'NOTIFICATION.MESSAGE_SYSTEM_MAINTENANCE.TITLE';

    public foldable = false;
}

export class EmailConfirmationStatus {
    needsConfirmation: boolean = false;

    static create(data: any): EmailConfirmationStatus {
        const result = new EmailConfirmationStatus();
        result.needsConfirmation = data.needsConfirmation;

        return result;
    }
}

export class EmailConfirmed {
    confirmed: boolean = false;

    static create(data: any): EmailConfirmed {
        const result = new EmailConfirmed();
        result.confirmed = data.confirmed;

        return result;
    }
}

export enum ApiNotificationType {
    ProfileActivated = 12,
    PlayStorePaymentHold = 31,
    MessageSystemMaintenance = 39,
}

export class ApiNotification {
    public type: number;

    public payload: object;

    public static create(): ApiNotification {
        return new ApiNotification();
    }

    public static createFromApi(data): ApiNotification {
        const notification = new ApiNotification();

        notification.type = data.type;
        notification.payload = data.payload || {};

        return notification;
    }
}

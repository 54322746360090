import {
    Component,
    ViewChild,
} from '@angular/core';

import {
    ApiService,
} from 'BKService';
import { EmailValidator } from 'BKShared/Validator';
import {
    GlobalModalService,
    ModalDialog,
} from '../../../service/global-modal.service';
import { Modal } from '../modal/modal';
import { BkModalEmailConfirm } from './bk-modal-email-confirm';
import { BkModalEmailReminder } from './bk-modal-email-reminder';

@Component({
               selector: 'bk-modal-email-change',
               template: `
                             <ng-container>
                                  <modal #modal
                                         no-border
                                         [closeIcon]="false"
                                         [backgroundClose]="false">
                                      <div class="modal-content">
                                          <div class="title">{{'MODAL.EMAIL_CHANGE.TITLE'|translate}}</div>
                                          <div class="text markdown first-last-nomargin checkbox-list" [innerHTML]="'MODAL.EMAIL_CHANGE.TEXT'|translate|markdown"></div>
                                          <text-field #emailInput
                                                      class="profile-edit-email__input"
                                                      type="email"
                                                      [placeholder]="'PROFILE_INFO.EMAIL' | translate"
                                                      [maxLength]="120"
                                                      [valid]="validate()"
                                                      [(value)]="email"
                                                      [helperText]="helperText|translate"
                                                      [errorText]="error|translate"
                                                      [trailingIcon]="trailingIcon"
                                          ></text-field>
                                      </div>
                                      <div class="button-bar">
                                          <flat-button (click)="cancel()">{{'MODAL.EMAIL_CHANGE.CANCEL'|translate}}</flat-button>
                                          <raised-button (click)="continue()">{{'MODAL.EMAIL_CHANGE.CONTINUE'|translate}}</raised-button>
                                      </div>
                                 </modal>
                             </ng-container>
                         `,
               styles: [require('./bk-modal-dialog.scss')]
           })
export class BkModalEmailChange implements ModalDialog {
    static readonly typeId = 'BkModalEmailChange';

    @ViewChild('modal', { static: true }) private modal: Modal;

    private previousEmail: string;

    protected busy = false;

    protected pristine = true;

    protected pending = false;

    protected error = '';

    protected get trailingIcon(): string {
        return  this.pending ? 'hourglass-empty' : 'edit';
    }

    protected get helperText(): string {
        if (this.pending) return 'PROFILE_EDIT.EMAIL.CHANGE.PENDING_TEXT';
        if (this.pristine) return 'PROFILE_EDIT.EMAIL.CHANGE.HINT_TEXT';
        return '';
    }


    private _email: string = null;
    private get email() {
        return this._email;
    }

    private set email(address: string) {
        this._email = address;
        EmailValidator.validateEmailAddress(address);
    }

    constructor(
        private globalModalService: GlobalModalService,
        private apiService: ApiService
    ) {
    }

    validate(): boolean {
        return this.error === '' || this.pristine;
    }

    cancel() {
        this.close();
        this.globalModalService.open(BkModalEmailReminder.typeId, this.previousEmail);
    }

    continue() {
        this.busy = true;
        this.apiService.user.changeEmailAddress(this.email).then((value) => {
            const state = value.data.state || false;
            if (!state) throw new Error();

            this.close();
            this.globalModalService.open(BkModalEmailConfirm.typeId, this.email);

        }).catch(() => {
            this.error = 'PROFILE_EDIT.EMAIL.CHANGE.ERROR';
            this.busy = false;
        });
    }

    open(config) {
        this.previousEmail = config;
        this.email = '';
        this.busy = false;
        this.pristine = true;
        this.pending = false;
        this.error = '';

        this.modal.open();
    }

    close() {
        this.modal.close();
    }

    public get typeId(): string {
        return BkModalEmailChange.typeId;
    }
}
